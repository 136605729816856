import React, { useCallback, useLayoutEffect, useState } from "react";
import FilterForm from "./components/FilterForm";
import Frame from "../../components/Frame";
import { makeStyles } from "@material-ui/core";
import PageHeader from "../../components/PageHeader";
import axios from "axios";
import ScheduleSessionTable from "./components/ScheduleSessionTable";
import EnrollConfirmationDialog from "./components/EnrollConfirmationDialog";
import CancelConfirmationDialog from "./components/CancelConfirmationDialog";
import moment from "moment";
import ErrorFilterResultPlaceholder from "../../components/filters/ErrorFilterResultPlaceholder";
import EmptyFilterResultPlaceholder from "../../components/filters/EmptyFilterResultPlaceholder";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "./components/Toolbar";
import { dateComparator } from "../../utils/app.util";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import numeral from "numeral";
import { HOSTNAME } from "../../constants/api.constant";
import { FormattedMessage } from "react-intl";
import {
  retrieveSessionStatus
} from "../../utils/app.util";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  emptyFilterResult: {
    flex: "1 1 auto"
  },
  errorFilterResult: {
    flex: "1 1 auto"
  },
  progress: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  toolbar: {
    marginTop: theme.spacing(2)
  },
  statsData: {
    minWidth: 96
  }
}));

const ScheduleAppointmentsPage = () => {
  const classes = useStyles();

  /*
  Status for loading listing table
  */

  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingTableError, setLoadingTableError] = useState(false);
  
  const [
    enrollConfirmationDialogState,
    setEnrollConfirmationDialogState
  ] = React.useState({
    open: false
  });
  
  const [
    cancelConfirmationDialogState,
    setCancelConfirmationDialogState
  ] = React.useState({
    open: false
  });

  /*
  Day filters
   */

  const [filters, setFilters] = useState({
    currentDay: moment().startOf("day"),
    startDay: moment().startOf("day"),
    view: "DAILY",
    pageNumber: 0,
    category: null,
    instructor: null,
    storeBranch: null
  });

  const handleEnroll = entity => {    
    const sessionStatus = retrieveSessionStatus(entity);
    
    setEnrollConfirmationDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const handleCancel = entity => {
    setCancelConfirmationDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const handleCurrentDayChange = value => {
    setFilters({
      ...filters,
      currentDay: value,
      startDay: value,
      view: "DAILY"
    });
  };

  const handleValueChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleDatePrev = () => {
    if (filters.view === "DAILY") {
      const nextDay = moment(filters.currentDay).subtract(1, "days");

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: nextDay
      });
    } else if (filters.view === "WEEKLY") {
      let nextDay = moment(filters.currentDay).subtract(1, "weeks");

      const present = moment().startOf("day");

      if (nextDay.isBefore(present)) {
        nextDay = present;
      }

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: nextDay
      });
    } else {
      const startOfCurrentMonth = moment(filters.currentDay).startOf("month");
      const endOfPrevMonth = moment(startOfCurrentMonth).subtract(1, "days");
      const startOfPrevMonth = moment(endOfPrevMonth).startOf("month");

      const currentDayOfMonth = moment(filters.currentDay).date();

      let nextDay = moment(startOfPrevMonth).add(currentDayOfMonth - 1, "days");
      if (nextDay.isAfter(endOfPrevMonth)) {
        nextDay = endOfPrevMonth;
      }

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: startOfPrevMonth
      });
    }
  };

  const handleDateToday = () => {
    setFilters({
      ...filters,
      currentDay: moment().startOf("day"),
      startDay: moment().startOf("day"),
      view: "DAILY"
    });
  };

  const handleDateNext = () => {
    if (filters.view === "DAILY") {
      const nextDay = moment(filters.currentDay).add(1, "days");

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: nextDay
      });
    } else if (filters.view === "WEEKLY") {
      const nextDay = moment(filters.currentDay).add(1, "weeks");

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: nextDay.startOf("week")
      });
    } else {
      const endOfCurrentMonth = moment(filters.currentDay).endOf("month");
      const startOfNextMonth = moment(endOfCurrentMonth).add(1, "days");
      const endOfNextMonth = moment(startOfNextMonth).endOf("month");

      const currentDayOfMonth = moment(filters.currentDay).date();

      let nextDay = moment(startOfNextMonth).add(currentDayOfMonth - 1, "days");
      if (nextDay.isAfter(endOfNextMonth)) {
        nextDay = endOfNextMonth;
      }

      setFilters({
        ...filters,
        currentDay: nextDay,
        startDay: startOfNextMonth
      });
    }
  };

  const handleViewChange = view => {
    if (view === filters.view) {
      return;
    }

    if (view === "DAILY") {
      setFilters({
        ...filters,
        startDay: moment(filters.currentDay),
        view: view
      });
    } else if (view === "WEEKLY") {
      setFilters({
        ...filters,
        startDay: moment(filters.currentDay).startOf("week"),
        view: view
      });
    } else {
      setFilters({
        ...filters,
        startDay: moment(filters.currentDay).startOf("month"),
        view: view
      });
    }
  };

  /*
  Data to display
   */

  const [sessionGroupByDay, setSessionGroupByDay] = useState({});

  useLayoutEffect(() => {
    let active = true;

    setLoadingTable(true);
    setLoadingTableError(false);

    const params = {
      ...filters
    };

    axios
      .post( `${HOSTNAME}/api/offeringScheduleSessions/actions/queryUpcoming`, createParams(params))
      .then(response => {
        if (active) {
          const data = response.data || {};
          

          let sessions = {};

          data.content && data.content.forEach(session => {
            if (sessions[session.day]) {
              sessions[session.day].push(session);
            } else {
              sessions[session.day] = [session];
            }
          });

          setSessionGroupByDay(sessions);

          setLoadingTableError(false);
        }
      })
      .catch(() => {
        active && setLoadingTableError(true);
      })
      .finally(() => {
        active && setLoadingTable(false);
      });

    return () => {
      active = false;
    };
  }, [filters]);

  const days = Object.keys(sessionGroupByDay).sort(dateComparator);

  const handleEnrollConfirmationDialogClose = React.useCallback(() => {
    setEnrollConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, [filters]);

  const enrollConfirmationDialog = (
    <EnrollConfirmationDialog
      {...enrollConfirmationDialogState}
      onClose={handleEnrollConfirmationDialogClose}
      onSuccess={() => {
        setFilters({
          ...filters,
        }); /* For refreshing */
      }}
      onBefore={targetEntity => {
      }}
      onAfter={targetEntity => {
      }}
    />
  );

  const handleCancelConfirmationDialogClose = React.useCallback(() => {
    setCancelConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, [filters]);

  const cancelConfirmationDialog = (
    <CancelConfirmationDialog
      {...cancelConfirmationDialogState}
      onClose={handleCancelConfirmationDialogClose}
      onSuccess={() => {
        setFilters({
          ...filters,
        }); /* For refreshing */
      }}
      onBefore={targetEntity => {
      }}
      onAfter={targetEntity => {
      }}
    />
  );

  useLayoutEffect(() => {
    let active = true;

    return () => {
      active = false;
    };
  }, [filters.daySize, filters.startDay]);

  const resultTables = days.map(day => (
    <ScheduleSessionTable
      key={day}
      day={day}
      sessions={sessionGroupByDay[day]}
      onEnroll={handleEnroll}
      onCancel={handleCancel}
    />
  ));

  return (
    <Frame className={classes.root}>
      <PageHeader
        section="Overview"
        title=<FormattedMessage id="navigation.scheduleAppointments" />
        right={
          null
        }
      />
      <FilterForm
        loading={loadingTable}
        onValueChange={handleValueChange}
        onCurrentDayChange={handleCurrentDayChange}
        values={filters}
      />
      <Toolbar
        className={classes.toolbar}
        date={filters.startDay}
        view={filters.view}
        onDatePrev={handleDatePrev}
        onDateToday={handleDateToday}
        onDateNext={handleDateNext}
        onViewChange={handleViewChange}
      />
      {loadingTableError ? (
        <ErrorFilterResultPlaceholder className={classes.errorFilterResult} />
      ) : loadingTable ? (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      ) : days && days.length > 0 ? (
        resultTables
      ) : (
        <EmptyFilterResultPlaceholder className={classes.emptyFilterResult} />
      )}
      {enrollConfirmationDialog}
      {cancelConfirmationDialog}
    </Frame>
  );
};

export default ScheduleAppointmentsPage;

const createParams = values => {
  const { startDay, view, category, instructor, storeBranch, pageNumber, status } = values;
  
  const present = moment().startOf("day");

  const params = new URLSearchParams();

  params.append("startDay", moment(startDay).isBefore(present) ? present.format("YYYY-MM-DD") : startDay.format("YYYY-MM-DD"));

  let daySize = 1;

  if (view === "WEEKLY") {
    daySize = 7;
  } else if (view === "MONTHLY") {
    daySize =
      moment(startDay)
        .endOf("month")
        .diff(startDay, "days") + 1;
  }
  
  if(moment(startDay).isBefore(present)){
    daySize -= present.diff(moment(startDay), "days");
  }
  

  params.append("daySize", daySize.toString());

  category && params.append(`categories[0]`, category.id);
  instructor && params.append(`instructors[0]`, instructor.id);
  storeBranch && params.append(`storeBranches[0]`, storeBranch.id);
  status && params.append("status", status);

  params.append("page", pageNumber);
  params.append("size", "99999");

  return params;
};
