import { matchPath } from "react-router";
import { CREATE_ACCOUNT_PATH, LOGIN_PATH, FORGET_PASSWORD_PATH, RESET_PASSWORD_PATH, ACTIVATION_PATH } from "../constants/route.constant";

export function matchLoginPath(pathname) {
  return matchPath(pathname, {
    path: LOGIN_PATH
  }) || matchPath(pathname, {
    path: CREATE_ACCOUNT_PATH
  }) || matchPath(pathname, {
    path: ACTIVATION_PATH
  });
}

export function matchForgetPasswordPath(pathname) {
  return matchPath(pathname, {
    path: FORGET_PASSWORD_PATH
  }) || matchPath(pathname, {
    path: RESET_PASSWORD_PATH
  });
}
