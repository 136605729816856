import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { Formik, useFormikContext } from "formik";
import oauth2Service from "../../../services/oauth2.service";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { sessionStatusLoadResetPassword } from "../../../security/SecurityFilter/actions/sessionStatus.action";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import InputAdornment from "@material-ui/core/InputAdornment";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FormikTextField from "../../../components/inputs/FormikTextField";
import { LOGIN_PATH, ACTIVATION_PATH } from "../../../constants/route.constant";
import { HOSTNAME } from "../../../constants/api.constant";
import axios from "axios";
import qs from "qs";
import { Redirect, withRouter, browserHistory } from "react-router";
import getAllFieldErrors from "../../../utils/api.util";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 3)
  },
  field: {
    marginBottom: theme.spacing(2)
  }
}));

const ActivationFormSchema = Yup.object().shape({
  token: Yup.string()
    .min(64, <FormattedMessage id="activation.form.token.error" />)
    .max(64, <FormattedMessage id="activation.form.token.error" />)
    .required(<FormattedMessage id="activation.form.token.error" />),
});

function ActivationForm(props) {
  const { handleSubmit, isSubmitting, isValid, dirty } = useFormikContext();

  const classes = useStyles();

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <FormikTextField
        className={classes.field}
        id="token"
        name="token"
        variant="outlined"
        fullWidth
        label={<FormattedMessage id="activation.form.token" />}
        autoComplete="false"
      />
      <Box position="relative">
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {!isSubmitting ? (
            <FormattedMessage id="activation.form.button.submit" />
          ) : (
            <FormattedMessage id="activation.form.button.loading" />
          )}
        </Button>
      </Box>
      <Grid container>
        <Grid item xs>
          <Link href={LOGIN_PATH} variant="body1">
            <FormattedMessage id="activation.login" />
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}

function FormikActivationForm() {  
  const [submitted, setSubmitted] = useState(false);
  
  if(submitted){
    setTimeout(function(){window.location.href = LOGIN_PATH}, 6000);
  }
  
  return (
    <div>
    {
      submitted ? (
        <div>
          <p><FormattedMessage id="activation.message.activated" /></p>
          <p><FormattedMessage id="activation.message.redirection" /></p>
        </div>
      ) : (
        <Formik
          validationSchema={ActivationFormSchema}
          onSubmit={(values, formikActions) => {
            return axios
              .patch(
                `${HOSTNAME}/public/accounts/activate`,
                qs.stringify({
                  ...values
                })
              )
              .then(response => {
                setSubmitted(true);
              }).catch(error => {
                if (error.response.status === 400) {
                  formikActions.setFieldError(
                    "token",
                    <FormattedMessage id="activation.form.token.error" />
                  );
                } else if (error.response.status === 500) {
                  formikActions.setFieldError(
                    "token",
                    <FormattedMessage id="activation.form.error.server" />
                  );
                } else {
                  formikActions.setFieldError(
                    "token",
                    <FormattedMessage id="activation.form.error.unknown" />
                  );
                }

                formikActions.setSubmitting(false);
              });
          }}
          initialValues={{ token: qs.parse(window.location.search, { ignoreQueryPrefix: true }).token }}
          component={ActivationForm}
        />
      )
    }
    </div>
  );
}

export default connect(
  null,
  { sessionStatusLoadResetPassword }
)(FormikActivationForm);
