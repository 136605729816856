import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { Formik, useFormikContext } from "formik";
import oauth2Service from "../../../services/oauth2.service";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { sessionStatusLoadForgetPassword } from "../../../security/SecurityFilter/actions/sessionStatus.action";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import InputAdornment from "@material-ui/core/InputAdornment";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FormikTextField from "../../../components/inputs/FormikTextField";
import { LOGIN_PATH, RESET_PASSWORD_PATH } from "../../../constants/route.constant";
import { HOSTNAME } from "../../../constants/api.constant";
import axios from "axios";
import qs from "qs";
import { Redirect, withRouter, browserHistory } from "react-router";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 3)
  },
  field: {
    marginBottom: theme.spacing(2)
  }
}));

const ForgetPasswordFormSchema = Yup.object().shape({
  email: Yup.string().email(<FormattedMessage id="forgetPassword.form.error.email" />)
    .required(<FormattedMessage id="forgetPassword.form.error.required" />)
});

function ForgetPasswordForm(props) {
  const { handleSubmit, isSubmitting, isValid, dirty } = useFormikContext();

  const classes = useStyles();

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <FormikTextField
        className={classes.field}
        id="email"
        name="email"
        variant="outlined"
        fullWidth
        label={<FormattedMessage id="forgetPassword.form.email" />}
        autoComplete="email"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <MailOutlineIcon color="action" />
            </InputAdornment>
          )
        }}
      />
      <Box position="relative">
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting || !isValid || !dirty}
        >
          {!isSubmitting ? (
            <FormattedMessage id="forgetPassword.form.button.submit" />
          ) : (
            <FormattedMessage id="forgetPassword.form.button.loading" />
          )}
        </Button>
      </Box>
      <Grid container>
        <Grid item xs>
          <Link href={LOGIN_PATH} variant="body1">
            <FormattedMessage id="forgetPassword.login" />
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}

function FormikForgetPasswordForm() {  
  return (
    <Formik
      validationSchema={ForgetPasswordFormSchema}
      onSubmit={(values, formikActions) => {
        return axios
          .post(
            `${HOSTNAME}/public/forgetPassword/verifyMail`,
            qs.stringify(values)
          ).then(response => {
            if(response.data){
              window.sessionStorage.setItem('email', values.email);
              window.location = RESET_PASSWORD_PATH;
            }else{
              formikActions.setFieldError(
                "email",
                <FormattedMessage id="forgetPassword.form.error.mailNotFound" />
              );
            }
            
          }).catch(response => {
            if (response.status === 400) {
              formikActions.setFieldError(
                "password",
                <FormattedMessage id="login.form.error.auth.failed" />
              );
            } else if (response.status === 500) {
              formikActions.setFieldError(
                "password",
                <FormattedMessage id="login.form.error.auth.server" />
              );
            } else {
              formikActions.setFieldError(
                "password",
                <FormattedMessage id="login.form.error.auth.unknown" />
              );
            }

            formikActions.setSubmitting(false);
          });
      }}
      initialValues={{ email: "" }}
      component={ForgetPasswordForm}
    />
  );
}

export default connect(
  null,
  { sessionStatusLoadForgetPassword }
)(FormikForgetPasswordForm);
