import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import ClassOutlinedIcon from "@material-ui/icons/ClassOutlined";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import WidgetsOutlinedIcon from "@material-ui/icons/WidgetsOutlined";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import EventBusyOutlinedIcon from "@material-ui/icons/EventBusyOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import {
  /*CUSTOMER_USER_MANAGEMENT_PATH,
  NOTIFICATION_MANAGEMENT_PATH,
  OFFERING_CATEGORY_MANAGEMENT_PATH,
  OFFERING_MANAGEMENT_PATH,
  OFFERING_SCHEDULE_SESSION_LIST_PATH,
  PACKAGE_PLAN_MANAGEMENT_PATH,
  UPCOMING_OFFERING_SCHEDULE_SESSION_LIST_PATH,
  REPORT_LIST_PATH,
  STAFF_USER_MANAGEMENT_PATH,
  SYSTEM_CONFIGURATION_PATH,
  CLOSED_BUSINESS_DAY_MANAGEMENT_PATH,
  MAIL_TEMPLATE_MANAGEMENT_PATH*/
  SCHEDULE_APPOINTMENTS_PATH,
  MY_APPOINTMENT_PATH,
  MY_PACKAGES_PATH,
  BUY_PACKAGE_PATH,
  MY_INFO_PATH
} from "../constants/route.constant";
/*import {
  ACCESS_CONTROL_ENTRY_CLOSED_BUSINESS_DAY,
  ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
  ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
  ACCESS_CONTROL_ENTRY_NOTIFICATION,
  ACCESS_CONTROL_ENTRY_OFFERING,
  ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
  ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
  ACCESS_CONTROL_ENTRY_REPORT,
  ACCESS_CONTROL_ENTRY_STAFF_USER,
  ACCESS_CONTROL_ENTRY_SYSTEM_CONFIGURATION,
  ACCESS_CONTROL_LEVEL_READ,
  ACCESS_CONTROL_LEVEL_YES
} from "../constants/app.constant";*/

export default [
  {
    title: "",
    items: [
      {
        title: "navigation.scheduleAppointments",
        href: SCHEDULE_APPOINTMENTS_PATH,
        icon: ScheduleOutlinedIcon
      },
      {
        title: "navigation.myAppointments",
        href: MY_APPOINTMENT_PATH,
        icon: NotificationsOutlinedIcon
      },
      {
        title: "navigation.myPackages",
        href: MY_PACKAGES_PATH,
        icon: FolderOutlinedIcon
      },
      {
        title: "navigation.myInfo",
        href: MY_INFO_PATH,
        icon: AccountBoxOutlinedIcon
      }
    ]
  }
];
