import React from "react";
import { Route } from "react-router";
import { useAccessControl } from "../hooks/accessControl.hook";
import * as PropTypes from "prop-types";
import AccessDeniedPage from "../pages/AccessDeniedPage";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  accessDenied: {
    height: "100%"
  }
}));

export function AccessControlRoute(props) {
  const { component: Component, accessControls, ...rest } = props;

  const classes = useStyles();

  const { isAccessGranted } = useAccessControl();

  const passThough = isAccessGranted(accessControls);

  return (
    <Route
      {...rest}
      render={props =>
        passThough ? (
          <Component {...props} />
        ) : (
          <AccessDeniedPage className={classes.accessDenied} />
        )
      }
    />
  );
}

AccessControlRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  accessControls: PropTypes.arrayOf(
    PropTypes.shape({
      entry: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired
    })
  )
};
