import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CheckIcon from "@material-ui/icons/Check";
import EmptyIcon from "../../../icons/EmptyIcon";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import localeService from "../../../services/locale.service";
import { FormattedMessage } from "react-intl";
import {
  BUY_PACKAGE_PATH
} from "../../../constants/route.constant";

const useStyles = makeStyles(() => ({
  root: {
    height: 160,
    width: "100%"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start"
  },
  icon: {
    width: 152,
    height: 152
  },
  item: {
    display: "block"
  }
}));

const SelectPurchasedPackagePlanForm = props => {
  const { purchasedPackagePlans, onSelect, selected } = props;

  const classes = useStyles();
  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);

  return (
    <PerfectScrollbar>
      <List className={classes.root}>
        {purchasedPackagePlans && purchasedPackagePlans.length > 0 ? (
          purchasedPackagePlans.map((purchasedPackagePlan, index) => {
            return (
              <React.Fragment key={purchasedPackagePlan.id}>
                <ListItem
                  button
                  dense
                  onClick={() => onSelect(purchasedPackagePlan)}
                >
                  <ListItemText
                    primary={purchasedPackagePlan.name}
                    secondary={
                      <React.Fragment>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          {purchasedPackagePlan.type}
                        </Typography>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          <FormattedMessage id="packages.effectiveDate" />: {purchasedPackagePlan.effectiveDate}
                        </Typography>
                        <Typography
                          className={classes.item}
                          component={"span"}
                          variant="body2"
                          color="textSecondary"
                        >
                          <FormattedMessage id="packages.expirationDate" />: {purchasedPackagePlan.expirationDate}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  {selected && selected.id === purchasedPackagePlan.id && (
                    <ListItemSecondaryAction>
                      <CheckIcon color={"secondary"} />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                {index !== purchasedPackagePlans.length - 1 && (
                  <Divider component="li" />
                )}
              </React.Fragment>
            );
          })
        ) : (
          <div className={classes.iconContainer}>
            <Link to={BUY_PACKAGE_PATH}>
              <Button color="secondary" variant="contained">
                Buy Package Plan
              </Button>
            </Link>
          </div>
        )}
      </List>
    </PerfectScrollbar>
  );
};

export default SelectPurchasedPackagePlanForm;
