import React from "react";
import clsx from "clsx";
import NavRouterLink from "./NavRouterLink";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import { getNavNodeLeftPadding } from "../utils/navigation.util";
import { useStyles } from "../styles/node.style";
import { FormattedMessage } from "react-intl";

const NavLeafNode = props => {
  const { node, className, depth, ...rest } = props;
  const { title, href, icon: Icon, label: Label } = node;

  const classes = useStyles();

  const style = {
    paddingLeft: getNavNodeLeftPadding(depth)
  };

  return (
    <ListItem
      {...rest}
      component="div"
      className={clsx(classes["leafNode"], className)}
      disableGutters
    >
      <Button
        activeClassName={classes["active"]}
        className={clsx(classes["leafButton"], `depth-${depth}`)}
        component={NavRouterLink}
        exact
        style={style}
        to={href}
      >
        {Icon && <Icon className={classes["icon"]} />}
        <FormattedMessage id={title} />
        {Label && (
          <span className={classes["label"]}>
            <Label />
          </span>
        )}
      </Button>
    </ListItem>
  );
};

export default NavLeafNode;
