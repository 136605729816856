import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NotFoundIcon from "../../icons/NotFoundIcon";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey["500"]
  },
  text: {
    marginTop: theme.spacing(2)
  },
  icon: {
    width: 280,
    height: "auto",
    fill: theme.palette.grey["500"]
  }
}));

const EmptyFilterResultPlaceholder = props => {
  const { className } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <NotFoundIcon className={classes.icon} />
      <Typography className={classes.text} variant="h6" color="inherit">
        <FormattedMessage id="result.message.emptyResult" />
      </Typography>
    </div>
  );
};

export default EmptyFilterResultPlaceholder;
