import React, { useState } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UpcomingAppointments from "./UpcomingAppointments";
import UpcomingPageHeader from "./UpcomingPageHeader";
import ListingTable from "./ListingTable";
import { HOSTNAME } from "../../../constants/api.constant";
import localeService from "../../../services/locale.service";
import { FormattedMessage } from "react-intl";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 1280,
    margin: 'auto',
    padding: '24px',
    maxWidth: '100%',
    display: "flex",
    flexDirection: "column"
  },
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <UpcomingPageHeader />
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={<FormattedMessage id="appointments.booked" />} {...a11yProps(0)} />
          <Tab label={<FormattedMessage id="appointments.waiting" />} {...a11yProps(1)} />
          <Tab label={<FormattedMessage id="appointments.pending" />} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <UpcomingAppointments
            listingTable={ListingTable}
            api={`${HOSTNAME}/api/transcripts`}
            method="post"
            status="BOOKED"
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <UpcomingAppointments
            listingTable={ListingTable}
            api={`${HOSTNAME}/api/transcripts`}
            method="post"
            status="WAIT_LISTED"
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <UpcomingAppointments
            listingTable={ListingTable}
            api={`${HOSTNAME}/api/transcripts`}
            method="post"
            status="PENDING_CONFIRMATION"
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}