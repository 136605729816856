import React, { useCallback, useState } from "react";
import axios from "axios";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import BaseEnrollConfirmationDialog from "./BaseEnrollConfirmationDialog";
import {
  retrieveInstructorName,
  retrieveSessionStatus
} from "../../../utils/app.util";
import { HOSTNAME } from "../../../constants/api.constant";
import { FormattedMessage } from "react-intl";

const EnrollConfirmationDialog = props => {
  const { open, onOpen, onClose, onSuccess, entity, ...rest } = props;
  
  const [loading, setLoading] = useState(false);

  const notifyApiError = useApiNotification();

  const loadPurchasedPackagePlans = () => {
    if(!entity){
      return;
    }
    
    let active = true;

    setLoading(true);

    setPurchasedPackagePlans([]);
    setSelectedPurchasedPackagePlan(null);

    axios
      .get(`/app/api/purchasedPackagePlans/available`, {
        params: {
          session: entity.id
        }
      })
      .then(response => {
        active && setPurchasedPackagePlans(response.data);
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to obtain package plans`,
              variant: "error"
            }
          },
          {
            message: `Unable to get package plans`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  };

  const [purchasedPackagePlans, setPurchasedPackagePlans] = useState([]);

  const [
    selectedPurchasedPackagePlan,
    setSelectedPurchasedPackagePlan
  ] = useState(null);

  const handlePurchasedPackagePlanSelect = useCallback(purchasedPackagePlan => {
    setSelectedPurchasedPackagePlan(purchasedPackagePlan);
  }, []);

  const resetForm = useCallback(() => {
    //setPurchasedPackagePlans([]);
    setSelectedPurchasedPackagePlan(null);
  }, []);

  const notify = useNotification();

  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    onClose();

    setSubmitting(true);


    const displayProgressTimeoutKey = setTimeout(() => {
      notify(<FormattedMessage id="notify.message.processing" />);
    }, PROGRESS_TIME_LIMIT);
    
    const sessionStatus = retrieveSessionStatus(entity);
    
    const action = (sessionStatus.code === "available" ? "enroll" : "waitList");

    const params = new URLSearchParams();

    params.append("session", entity.id);
    params.append("purchasedPackagePlan", selectedPurchasedPackagePlan.id);

    return axios
      .post(`${HOSTNAME}/api/actions/transcripts/${action}`, params)
      .then(() => {
        notify(
          `Appointment is confirmed`,
          "success"
        );

        resetForm();

        onSuccess();
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        /*if (error.response.status === 400) {
          onOpen();
        }*/
        const { response } = error;

        notifyApiError(
          error.response.status,
          {
            400: {
              message: <FormattedMessage id="notify.message.errorTryAgain" />,
              variant: "error"
            }
          },
          {
            message: response.data.message,
            variant: "error"
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        setSubmitting(false);
      });
  };

  const dirty =
    selectedPurchasedPackagePlan !== null;

  const isDefault =
    selectedPurchasedPackagePlan === null;

  const isValid =
    (selectedPurchasedPackagePlan !== null) ||
    isDefault;

  return (
    <BaseEnrollConfirmationDialog
      {...rest}
      title={<FormattedMessage id="scheduleAppointments.enrollService" />}
      open={open}
      onClose={onClose}
      loading={loading}
      entity={entity}
      purchasedPackagePlans={purchasedPackagePlans}
      onPurchasedPackagePlanSelect={handlePurchasedPackagePlanSelect}
      selectedPurchasedPackagePlan={selectedPurchasedPackagePlan}
      onOpen={loadPurchasedPackagePlans}
      onSubmit={handleSubmit}
      onReset={resetForm}
      dirty={dirty}
      isValid={isValid}
      isSubmitting={isSubmitting}
    />
  );
};

export default EnrollConfirmationDialog;
