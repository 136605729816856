import moment from "moment";

export function extractSelectOption(options, key, value) {
  if (value) {
    for (let i = 0; i < options.length; i++) {
      if (options[i][key] === value) {
        return options[i];
      }
    }
  }

  return null;
}

export function retrieveInstructorName(instructor) {
  return instructor && (instructor.firstName || instructor.lastName)
    ? instructor.firstName
      ? instructor.lastName
        ? `${instructor.firstName} ${instructor.lastName}`
        : instructor.firstName
      : instructor.lastName
    : "";
}

/*
OfferingScheduleSessionStatusEnum {
    ACTIVE,
    CANCELLED,
    COMPLETED,
    CLOSED;
}
TranscriptStatusEnum {
    BOOKED,
    WAIT_LISTED,
    CANCELLED,
    PENDING_CONFIRMATION,
    EXPIRED_CONFIRMATION;
}
*/

export function retrieveSessionStatus(session) {
  let status = {code: "", full: true, waitListFull: true, inWaitList: false, isEnrolled: false, isPending: false, canEnroll: false, canWaitList: false, canWaitListConfirm: false, canCancel: false};
  
  const enrollmentStartTime = moment(session.enrollmentStartTime)
  const enrollmentEndTime = moment(session.enrollmentEndTime)
  const waitListCloseTime = moment(session.waitListCloseTime);
  const cancellationCloseTime = moment(session.cancellationCloseTime);
  
  const present = moment();
  
  if(typeof session.transcript !== "undefined"){
    if(session.transcript.status === "BOOKED" || session.transcript.status === "WAIT_LISTED"){
      status.isEnrolled = true;
      status.canCancel = true;
    }
    
    if(session.transcript.status === "BOOKED"){
      status.code = "enrolled";
    }
    if(session.transcript.status === "WAIT_LISTED"){
      status.code = "waitlisted";
    }
  }else{
    if(session.status === "BOOKED" || session.status === "WAIT_LISTED"){
      status.isEnrolled = true;
      status.canCancel = true;
    }
    
    if(session.status === "BOOKED"){
      status.code = "enrolled";
    }
    if(session.status === "WAIT_LISTED"){
      status.code = "waitlisted";
    }
  }
  
  if(session.bookedCount < session.onlineCapacity){
    status.full = false;
    if(status.code === ""){
      status.canEnroll = true;
      status.code = "available";
    }
  }
  
  if(session.waitListedCount < session.waitListCapacity){
    status.waitListFull = false;
    if(status.code === ""){
      status.canWaitList = true;
      status.code = "waitlist_available";
    }
  }
  
  if(enrollmentStartTime.isAfter(present) || enrollmentEndTime.isBefore(present)){
    status.canEnroll = false;
    
    if(status.code !== "enrolled"){
      status.code = (enrollmentStartTime.isAfter(present) ? "not_open" : "closed");
    }
  }
  
  if(status.code === "" || (status.full && status.waitListFull)){
    status.code = "full";
    status.canEnroll = status.canWaitList = false;
  }
  
  if(status.code === "enrolled"){
    status.canEnroll = status.canWaitList = false;
  }
  
  if(waitListCloseTime.isBefore(present)) {
    status.canWaitList = false;
  }
  
  if(cancellationCloseTime.isBefore(present)) {
    status.canCancel = false;
  }
  
  if(session.status === "PENDING_CONFIRMATION" && typeof session.waitListConfirmationCloseTime !== "undefined") {
    const waitListConfirmationCloseTime = moment(session.waitListConfirmationCloseTime);
    if(waitListConfirmationCloseTime.isAfter(present)) {
      status.canWaitListConfirm = true;
    }
  }
  
  if(!status.canEnroll && !status.canWaitList && !status.canCancel){
    if(status.code !== 'not_open') status.code = 'closed';
  }
  
  return status;
}

export function startTimeEndTimeComparator(a, b) {
  const momentStartTimeA = moment(a.startTime, "HH:mm");
  const momentStartTimeB = moment(b.startTime, "HH:mm");

  const momentEndTimeA = moment(a.endTime, "HH:mm");
  const momentEndTimeB = moment(b.endTime, "HH:mm");

  if (momentStartTimeA.isBefore(momentStartTimeB)) {
    return -1;
  } else if (momentStartTimeA.isAfter(momentStartTimeB)) {
    return 1;
  }

  if (momentEndTimeA.isBefore(momentEndTimeB)) {
    return -1;
  } else if (momentEndTimeA.isAfter(momentEndTimeB)) {
    return 1;
  }
  
  if(a.id || b.id){
    return a.id.localeCompare(b.id);
  }

  return 0;
}

export function dateComparator(a, b) {
  const momentA = moment(a);
  const momentB = moment(b);

  if (momentA.isBefore(momentB)) {
    return -1;
  } else if (momentA.isAfter(momentB)) {
    return 1;
  }

  return 0;
}

export function getCustomerUserName(customerUser) {
  return customerUser && (customerUser.firstName || customerUser.lastName)
    ? customerUser.firstName
      ? customerUser.lastName
        ? `${customerUser.firstName} ${customerUser.lastName}`
        : customerUser.firstName
      : customerUser.lastName
    : "";
}

export function tableResponsive() {
  document.querySelectorAll('table:not(.responsive)').forEach(function(table){
    let headertext = [],
      headers = table.querySelectorAll(':scope > thead > tr > th'),
      rows = table.querySelectorAll(':scope > tbody > tr');

    var canResponsive = true;
    rows.forEach(function(row) {
      if (row.querySelectorAll(':scope > td').length != headers.length) {
        canResponsive = false;
      }
    });

    if (headers.length && canResponsive) {
      table.classList.add('responsive');;

      headers.forEach(function(header) {
        headertext.push(header.innerHTML.replace(/^\s+|\s+$/gm, ''));
      })

      rows.forEach(function(row) {
        var cols = row.querySelectorAll(':scope > td');
        cols.forEach(function(col, index) {
          col.setAttribute('data-th', headertext[index]);
        });
      });
    }
  });
}

setInterval(tableResponsive, 1000);