export function getFieldErrorIfPossible(response, fieldName) {
  const fieldErrors = response.details;

  if (fieldErrors) {
    for (let i = 0; i < fieldErrors.length; i++) {
      if (fieldErrors[i].parameter === fieldName) {
        return fieldErrors[i].message;
      }
    }
  }

  return null;
}

export default function getAllFieldErrors(response) {
  const errors = {};

  const fieldErrors = response.data.details;
  if (fieldErrors) {
    for (let i = 0; i < fieldErrors.length; i++) {
      fieldErrors[i].parameter &&
        (errors[fieldErrors[i].parameter] = fieldErrors[i].message);
    }
  }

  return errors;
}
