import {
  SESSION_STATUS_COMPLETED_RESTORE,
  SESSION_STATUS_FAILED_GENERAL,
  SESSION_STATUS_FAILED_UNAUTHENTICATED,
  SESSION_STATUS_INITIAL,
  SESSION_STATUS_NEW,
  SESSION_STATUS_LOAD_LOGIN,
  SESSION_STATUS_LOAD_RESTORE,
  SESSION_STATUS_COMPLETED_LOGIN
} from "../actions/sessionStatus.action";

const initialState = SESSION_STATUS_INITIAL;

const sessionStatus = function(state = initialState, action) {
  switch (action.type) {
    case SESSION_STATUS_INITIAL: {
      return SESSION_STATUS_INITIAL;
    }
    case SESSION_STATUS_NEW: {
      return SESSION_STATUS_NEW;
    }
    case SESSION_STATUS_COMPLETED_LOGIN: {
      return SESSION_STATUS_COMPLETED_LOGIN;
    }
    case SESSION_STATUS_COMPLETED_RESTORE: {
      return SESSION_STATUS_COMPLETED_RESTORE;
    }
    case SESSION_STATUS_LOAD_LOGIN: {
      return SESSION_STATUS_LOAD_LOGIN;
    }
    case SESSION_STATUS_LOAD_RESTORE: {
      return SESSION_STATUS_LOAD_RESTORE;
    }
    case SESSION_STATUS_FAILED_UNAUTHENTICATED: {
      return SESSION_STATUS_FAILED_UNAUTHENTICATED;
    }
    case SESSION_STATUS_FAILED_GENERAL: {
      return SESSION_STATUS_FAILED_GENERAL;
    }
    default: {
      return state;
    }
  }
};

export default sessionStatus;
