import PerfectScrollbar from "react-perfect-scrollbar";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, { useState } from "react";
import moment from "moment";
import numeral from "numeral";
import {
  retrieveInstructorName,
  retrieveSessionStatus,
  startTimeEndTimeComparator
} from "../../../utils/app.util";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import localeService from "../../../services/locale.service";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3)
  },
  table: {
    whiteSpace: "nowrap"
  },
  date: {
    width: 240
  },
  duration: {
    width: 120
  },
  signedIn: {
    width: 120
  },
  instructor: {
    width: 200
  }
}));

const ScheduleSessionTable = props => {
  const {
    day,
    sessions,
    onEnroll,
    onCancel
  } = props;

  const classes = useStyles();
  
  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);

  const dayMoment = moment(day);

  sessions.sort(startTimeEndTimeComparator);
  
  return (
    <Paper className={classes.paper}>
      <PerfectScrollbar>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.date}>
                {dayMoment.format("D/M/YYYY")/*locale == "en" ? dayMoment.format("ddd, DD MMMM YYYY") : dayMoment.format("LL[（]dd[）]")*/}
              </TableCell>
              <TableCell className={classes.duration}><FormattedMessage id="scheduleAppointments.duration" /></TableCell>
              <TableCell><FormattedMessage id="scheduleAppointments.service" /></TableCell>
              <TableCell className={classes.instructor}><FormattedMessage id="scheduleAppointments.location" /></TableCell>
              <TableCell className={classes.signedIn}><FormattedMessage id="scheduleAppointments.status" /></TableCell>
              <TableCell className={classes.signedIn}><FormattedMessage id="scheduleAppointments.action" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map(session => {
              const {
                id,
                startTime,
                endTime,
                offeringName,
                offeringSchedule,
                instructor,
                onlineCapacity,
                storeBranch,
                bookedCount
              } = session;

              const startTimeMoment = moment(startTime, "HH:mm");
              const endTimeMoment = moment(endTime, "HH:mm");

              const startTimeText = startTimeMoment.format("HH:mm");
              const endTimeText = endTimeMoment.format("HH:mm");

              const instructorName = retrieveInstructorName(instructor);
              
              const sessionStatus = retrieveSessionStatus(session);

              const duration = numeral(
                endTimeMoment.diff(startTimeMoment, "hours", true)
              ).format("0.0");
              
              if(sessionStatus.code == 'closed') return;

              return (
                <TableRow
                  key={`${startTimeText} ${endTimeText} ${instructorName}`}
                >
                  <TableCell>{`${startTimeText} - ${endTimeText}`}</TableCell>
                  <TableCell>
                    {duration} hour{duration >= 2 ? "s" : ""}
                  </TableCell>
                  <TableCell>{offeringName}</TableCell>
                  <TableCell>{storeBranch.name}</TableCell>
                  <TableCell>{<FormattedMessage id={"session.status." + sessionStatus.code} />}</TableCell>
                  <TableCell>
                    {sessionStatus.canEnroll && (
                      <Button
                        color="default"
                        size="small"
                        onClick={() => onEnroll(session)}
                      >
                        <FormattedMessage id="scheduleAppointments.book" />
                      </Button>
                    )}
                    {sessionStatus.canWaitList && (
                      <Button
                        color="default"
                        size="small"
                        onClick={() => onEnroll(session)}
                      >
                        <FormattedMessage id="scheduleAppointments.waitList" />
                      </Button>
                    )}
                    {sessionStatus.canCancel && (
                      <Button
                        color="default"
                        size="small"
                        onClick={() => onCancel(session)}
                      >
                        <FormattedMessage id="scheduleAppointments.cancel" />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Paper>
  );
};

export default ScheduleSessionTable;
