import React, { useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CREATE_ACCOUNT_PATH, LOGIN_PATH, FORGET_PASSWORD_PATH, RESET_PASSWORD_PATH, ACTIVATION_PATH } from "./constants/route.constant";
import CreateAccountPage from "./pages/CreateAccountPage";
import LoginPage from "./pages/LoginPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ActivationPage from "./pages/ActivationPage";
import SecuredRoute from "./security/SecuredRoute";
import SecurityFilter from "./security/SecurityFilter";
import SwitchableLayout from "./layouts/SwitchableLayout";
import { ThemeProvider } from "@material-ui/core/styles";
import store from "./stores";
import theme from "./themes";
import "./init/axios.init";
import { CssBaseline } from "@material-ui/core";
import localeService from "./services/locale.service";
import { IntlProvider } from "react-intl";
import { MESSAGES } from "./constants/locale.constant";

function App() {
  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);
  
  return (
    <Provider store={store}>
      <IntlProvider locale={locale} messages={MESSAGES[locale]}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Router>
            <SecurityFilter>
              <Switch>
                <Route path={CREATE_ACCOUNT_PATH} component={CreateAccountPage} />
                <Route path={LOGIN_PATH} component={LoginPage} />
                <Route path={FORGET_PASSWORD_PATH} component={ForgetPasswordPage} />
                <Route path={RESET_PASSWORD_PATH} component={ResetPasswordPage} />
                <Route path={ACTIVATION_PATH} component={ActivationPage} />
                <SecuredRoute component={SwitchableLayout} />
              </Switch>
            </SecurityFilter>
          </Router>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  );
}

export default App;
