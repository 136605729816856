import React from "react";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const logoWidth = 200;

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%"
  },
  logo: {
    width: logoWidth
  }
}));

export default function LoadingPage(props) {
  const classes = useStyles();

  const { message = "Loading session, please wait..." } = props;

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justify="center"
      direction="column"
    >
      <Grid item>
        <img
          className={classes.logo}
          src="/static/images/logos/logo.png"
          alt="logo"
        />
      </Grid>
      <Box width={logoWidth / 0.618} mt={4} mb={2}>
        <LinearProgress color="primary" />
      </Box>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
}
