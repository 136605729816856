import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import LockIcon from "../icons/LockIcon";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey["500"]
  },
  text: {
    marginTop: theme.spacing(2)
  },
  icon: {
    width: 160,
    height: "auto",
    fill: theme.palette.grey["500"]
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

export default function AccessDeniedPage(props) {
  const classes = useStyles();

  const { className } = props;

  return (
    <div className={clsx(classes.root, className)}>
      <LockIcon className={classes.icon} />
      <Typography
        className={classes.text}
        align="center"
        variant="h6"
        color="inherit"
      >
        You are not allowed to access the page
      </Typography>
      <Button
        className={classes.button}
        color="primary"
        component={RouterLink}
        to="/"
        variant="outlined"
      >
        Back to home
      </Button>
    </div>
  );
}
