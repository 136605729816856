import React from "react";
import ListingTable from "./components/ListingTable";
import PreviousListingTable from "./components/PreviousListingTable";
import DisplayDialog from "./components/DisplayDialog";
import RemoveConfirmationDialog from "./components/RemoveConfirmationDialog";
import FilterForm from "./components/FilterForm";
import CreateFormDialog from "./components/CreateFormDialog";
import EditFormDialog from "./components/EditFormDialog";
import UpcomingAppointmentsTabs from "./components/UpcomingAppointmentsTabs";
import PreviousAppointments from "./components/PreviousAppointments";
import PreviousPageHeader from "./components/PreviousPageHeader";
import { HOSTNAME } from "../../constants/api.constant";


const MyAppointment = () => {
  return (
    <div>
      <UpcomingAppointmentsTabs />
      <PreviousAppointments
        pageHeader={PreviousPageHeader}
        listingTable={PreviousListingTable}
        api={`${HOSTNAME}/api/transcripts/actions/queryHistory`}
        method="post"
      />
    </div>
  );
};

export default MyAppointment;
