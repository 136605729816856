import React from "react";
import { TextField } from "@material-ui/core";
import AutocompletePlus from "./AutocompletePlus";
import { useFormikContext } from "formik";

export default function(props) {
  const { name, TextFieldProps, ...rest } = props;

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    isSubmitting
  } = useFormikContext();

  return (
    <AutocompletePlus
      {...rest}
      value={values[name]}
      onChange={(event, value) => {
        setFieldValue(name, value);
      }}
      renderInput={params => {
        return (
          <TextField
            {...params}
            {...TextFieldProps}
            name={name}
            onBlur={handleBlur}
            disabled={isSubmitting}
            error={Boolean(touched.gender && errors.gender)}
            helperText={touched.gender && errors.gender}
          />
        );
      }}
      disabled={isSubmitting}
    />
  );
}
