import Grid from "@material-ui/core/Grid";
import React, { useState, useCallback } from "react";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import axios from "axios";
import LoadableSelect from "../../../components/inputs/LoadableSelect";
import AutoCompleteOffTextField from "../../../components/inputs/AutoCompleteOffTextField";
import { retrieveInstructorName, getMomentZhDefine } from "../../../utils/app.util";
import { HOSTNAME } from "../../../constants/api.constant";
import { FormattedMessage } from "react-intl";
import localeService from "../../../services/locale.service";


const FilterForm = props => {
  const { loading, onValueChange, onCurrentDayChange, values } = props;
  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);
    
  const present = moment().startOf("day");

  const offeringCategoryRequest = useCallback(() => {
    return axios.get(`${HOSTNAME}/api/offeringCategories`);
  }, []);

  const instructorRequest = useCallback(() => {
    return axios.get(`${HOSTNAME}/api/staffUsers`, {
      params: { instructor: true }
    });
  }, []);

  const storeBranchRequest = useCallback(() => {
    return axios.get(`${HOSTNAME}/api/storeBranches`);
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={6}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            label={<FormattedMessage id="scheduleAppointments.currentDay" />}
            inputVariant="outlined"
            format={locale == "en" ? "DD/MM/YYYY" : "DD/MM/YYYY"}
            placeholder="DD/MM/YYYY"
            minDate={present}
            margin="dense"
            InputAdornmentProps={{ position: "start" }}
            KeyboardButtonProps={{ size: "small" }}
            TextFieldComponent={AutoCompleteOffTextField}
            value={values.currentDay}
            onChange={onCurrentDayChange}
            disabled={loading}
            cancelLabel={<FormattedMessage id="datepicker.cancel" />}
            okLabel={<FormattedMessage id="datepicker.ok" />}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <LoadableSelect
            fullWidth
            label={<FormattedMessage id="scheduleAppointments.service" />}
            margin="dense"
            variant="outlined"
            request={offeringCategoryRequest}
            getOptionLabel={option =>
              option && option.name ? option.name : ""
            }
            value={values.offeringCategory}
            onChange={(event, value) => {
              onValueChange("category", value);
            }}
            disabled={loading}
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <LoadableSelect
            fullWidth
            label={<FormattedMessage id="scheduleAppointments.location" />}
            variant="outlined"
            margin="dense"
            request={storeBranchRequest}
            getOptionLabel={option =>
              option && option.name ? option.name : ""
            }
            value={values.storeBranch}
            onChange={(event, value) => {
              onValueChange("storeBranch", value);
            }}
            disabled={loading}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default FilterForm;
