import {TextField} from "@material-ui/core";
import React from "react";

const AutoCompleteOffTextField = ({ inputProps, ...rest }) => (
  <TextField
    {...rest}
    inputProps={{
      ...inputProps,
      autoComplete: "off"
    }}
  />
);

export default AutoCompleteOffTextField;
