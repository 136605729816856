import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { SCHEDULE_APPOINTMENTS_PATH, MY_APPOINTMENT_PATH, MY_PACKAGES_PATH, BUY_PACKAGE_PATH, BUY_PACKAGE_SUCCESS_PATH, BUY_PACKAGE_CANCEL_PATH, INVOICE_PATH, MY_INFO_PATH } from "../constants/route.constant";
import Error404Page from "../pages/Error404Page";
import { AccessControlRoute } from "./AccessControlRoute";
/*import {
  ACCESS_CONTROL_ENTRY_CLOSED_BUSINESS_DAY,
  ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
  ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
  ACCESS_CONTROL_ENTRY_NOTIFICATION,
  ACCESS_CONTROL_ENTRY_OFFERING,
  ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
  ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
  ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
  ACCESS_CONTROL_ENTRY_REPORT,
  ACCESS_CONTROL_ENTRY_STAFF_USER,
  ACCESS_CONTROL_ENTRY_SYSTEM_CONFIGURATION,
  ACCESS_CONTROL_LEVEL_READ,
  ACCESS_CONTROL_LEVEL_YES
} from "../constants/app.constant";*/
/*import CustomerUserManagement from "../pages/CustomerUserManagement";
import OfferingManagement from "../pages/OfferingManagement";
import StaffUserManagement from "../pages/StaffUserManagement";
import OfferingCategoryManagement from "../pages/OfferingCategoryManagement";
import OfferingScheduleManagement from "../pages/OfferingScheduleManagement";
import PackagePlanManagement from "../pages/PackagePlanManagement";
import NotificationManagement from "../pages/NotificationManagement";
import ReportList from "../pages/ReportList";
import OfferingScheduleSessionManagement from "../pages/OfferingScheduleSessionManagement";
import UpcomingOfferingScheduleSessionList from "../pages/UpcomingOfferingScheduleSessionList/UpcomingOfferingScheduleSessionList";
import OfferingScheduleSessionList from "../pages/OfferingScheduleSessionList/OfferingScheduleSessionList";
import SystemConfiguration from "../pages/SystemConfiguration/SystemConfiguration";
import CustomerUserPackagePlanManagement from "../pages/CustomerUserPackagePlanManagement/CustomerUserPackagePlanManagement";
import OfferingScheduleSessionTranscriptManagement from "../pages/OfferingScheduleSessionTranscriptManagement/OfferingScheduleSessionTranscriptManagement";
import ClosedBusinessDayManagement from "../pages/ClosedBusinessDayManagement";
import MailTemplateManagement from "../pages/MailTemplateManagement";
import CustomerUserPurchasedPackagePlanUsageListing from "../pages/CustomerUserPurchasedPackagePlanUsageListing/CustomerUserPurchasedPackagePlanUsageListing";
import TranscriptHistoryList from "../pages/TranscriptHistoryList";*/
import ScheduleAppointmentsPage from "../pages/ScheduleAppointmentsPage";
import MyAppointmentPage from "../pages/MyAppointmentPage";
import MyPackagesPage from "../pages/MyPackagesPage";
import BuyPackagePage from "../pages/BuyPackagePage";
import BuyPackageSuccessPage from "../pages/BuyPackagePage/BuyPackageSuccess";
import BuyPackageCancelPage from "../pages/BuyPackagePage/BuyPackageCancel";
import MyInfoPage from "../pages/MyInfoPage";


export default function AppRoute() {
  return (
    <Switch>
      <Route
        path={SCHEDULE_APPOINTMENTS_PATH}
        component={ScheduleAppointmentsPage}
        exact
      />
      <Route
        path={MY_APPOINTMENT_PATH}
        component={MyAppointmentPage}
        exact
      />
      <Route
        path={MY_PACKAGES_PATH}
        component={MyPackagesPage}
        exact
      />
      <Route
        path={MY_INFO_PATH}
        component={MyInfoPage}
        exact
      />
      <Route
        path={BUY_PACKAGE_PATH}
        component={BuyPackagePage}
        exact
      />
      <Route
        path={BUY_PACKAGE_SUCCESS_PATH}
        component={BuyPackageSuccessPage}
        exact
      />
      <Route
        path={BUY_PACKAGE_CANCEL_PATH}
        component={BuyPackageCancelPage}
        exact
      />
      <Route
        path="/"
        component={ScheduleAppointmentsPage}
        exact
      />
      <Redirect
        from="/"
        to={MyAppointmentPage}
        exact
      />
      <Route component={Error404Page} />
    </Switch>
  );
}
