import { useSelector } from "react-redux";
/*import {
  ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
  ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
  ACCESS_CONTROL_ENTRY_NOTIFICATION,
  ACCESS_CONTROL_ENTRY_OFFERING,
  ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
  ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
  ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
  ACCESS_CONTROL_ENTRY_STAFF_USER,
  ACCESS_CONTROL_ENTRY_REPORT,
  ACCESS_CONTROL_ENTRY_SYSTEM_CONFIGURATION,
  ACCESS_CONTROL_ENTRY_CLOSED_BUSINESS_DAY,
  ACCESS_CONTROL_LEVEL_YES,
  ACCESS_CONTROL_LEVEL_READ,
  ACCESS_CONTROL_LEVEL_UNRESTRICTED,
  ACCESS_CONTROL_LEVEL_UPDATE
} from "../constants/app.constant";*/

export function useAccessControl() {
  const { accessControlPoints } = useSelector(
    state => state.session.data.personalSettings
  );

  const isAccessGranted = accessControls => {
    let accessDenied = false;

    /*if (accessControls) {
      for (let i = 0; i < accessControls.length; i++) {
        if (
          accessControlPoints[accessControls[i].entry] == null ||
          accessControlPoints[accessControls[i].entry] < accessControls[i].level
        ) {
          accessDenied = true;
          break;
        }
      }
    }*/

    return !accessDenied;
  };

  const isReadStaffUserAllowed = () => {
    return true;
  };

  const isCreateStaffUserAllowed = () => {
    return true;
  };

  const isEditStaffUserAllowed = () => {
    return true;
  };

  const isDeleteStaffUserAllowed = () => {
    return true;
  };

  const isReadOfferingCategoryAllowed = () => {
    return true;
  };

  const isCreateOfferingCategoryAllowed = () => {
    return true;
  };

  const isEditOfferingCategoryAllowed = () => {
    return true;
  };

  const isDeleteOfferingCategoryAllowed = () => {
    return true;
  };

  const isReadCustomerUserAllowed = () => {
    return true;
  };

  const isCreateCustomerUserAllowed = () => {
    return true;
  };

  const isEditCustomerUserAllowed = () => {
    return true;
  };

  const isDeleteCustomerUserAllowed = () => {
    return true;
  };

  const isReadOfferingAllowed = () => {
    return true;
  };

  const isCreateOfferingAllowed = () => {
    return true;
  };

  const isEditOfferingAllowed = () => {
    return true;
  };

  const isDeleteOfferingAllowed = () => {
    return true;
  };

  const isReadOfferingScheduleAllowed = () => {
    return true;
  };

  const isCreateOfferingScheduleAllowed = () => {
    return true;
  };

  const isEditOfferingScheduleAllowed = () => {
    return true;
  };

  const isDeleteOfferingScheduleAllowed = () => {
    return true;
  };

  const isReadPackagePlanAllowed = () => {
    return true;
  };

  const isCreatePackagePlanAllowed = () => {
    return true;
  };

  const isEditPackagePlanAllowed = () => {
    return true;
  };

  const isDeletePackagePlanAllowed = () => {
    return true;
  };

  const isReadNotificationAllowed = () => {
    return true;
  };

  const isCreateNotificationAllowed = () => {
    return true;
  };

  const isEditNotificationAllowed = () => {
    return true;
  };

  const isDeleteNotificationAllowed = () => {
    return true;
  };

  const isReadMailTemplateAllowed = () => {
    return true;
  };

  const isCreateMailTemplateAllowed = () => {
    return true;
  };

  const isEditMailTemplateAllowed = () => {
    return true;
  };

  const isDeleteMailTemplateAllowed = () => {
    return true;
  };

  const isReportAllowed = () => {
    return true;
  };

  const isSystemConfigurationAllowed = () => {
    return true;
  };

  const isClosedBusinessDayAllowed = () => {
    return true;
  };

  return {
    isAccessGranted,
    isReadStaffUserAllowed,
    isCreateStaffUserAllowed,
    isEditStaffUserAllowed,
    isDeleteStaffUserAllowed,
    isReadOfferingCategoryAllowed,
    isCreateOfferingCategoryAllowed,
    isEditOfferingCategoryAllowed,
    isDeleteOfferingCategoryAllowed,
    isReadCustomerUserAllowed,
    isCreateCustomerUserAllowed,
    isEditCustomerUserAllowed,
    isDeleteCustomerUserAllowed,
    isReadOfferingAllowed,
    isCreateOfferingAllowed,
    isEditOfferingAllowed,
    isDeleteOfferingAllowed,
    isReadOfferingScheduleAllowed,
    isCreateOfferingScheduleAllowed,
    isEditOfferingScheduleAllowed,
    isDeleteOfferingScheduleAllowed,
    isReadPackagePlanAllowed,
    isCreatePackagePlanAllowed,
    isEditPackagePlanAllowed,
    isDeletePackagePlanAllowed,
    isReadNotificationAllowed,
    isCreateNotificationAllowed,
    isEditNotificationAllowed,
    isDeleteNotificationAllowed,
    isReadMailTemplateAllowed,
    isCreateMailTemplateAllowed,
    isEditMailTemplateAllowed,
    isDeleteMailTemplateAllowed,
    isReportAllowed,
    isSystemConfigurationAllowed,
    isClosedBusinessDayAllowed
  };
}
