import typography from "../typography";

export default {
  inputMarginDense: {
    height: 19
  },
  marginDense: {
    ...typography.body1
  },
  multiline: {
    ...typography.body1
  }
};
