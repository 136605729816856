import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import PerfectScrollbar from "react-perfect-scrollbar";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "0 !important"
  },
  card: {
    position: "relative"
  },
  content: {
    padding: 0
  },
  inner: {
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  table: {
    whiteSpace: "nowrap"
  },
  columnAction: {
    minWidth: 120
  }
}));

const ListingTable = props => {
  const {
    className,
    entities,
    onView,
    onEdit,
    onDelete,
    totalCount,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    handlePageSizeChange,
    loading,
    ...rest
  } = props;

  const classes = useStyles();

  const {
    isReadCustomerUserAllowed,
    isEditCustomerUserAllowed,
    isDeleteCustomerUserAllowed
  } = useAccessControl();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><FormattedMessage id="appointments.day" /></TableCell>
                    <TableCell><FormattedMessage id="appointments.time" /></TableCell>
                    <TableCell><FormattedMessage id="appointments.duration" /></TableCell>
                    <TableCell><FormattedMessage id="appointments.service" /></TableCell>
                    <TableCell><FormattedMessage id="appointments.location" /></TableCell>
                    <TableCell><FormattedMessage id="appointments.status" /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities.map(entity => (
                    <TableRow hover key={entity.id}>
                      <TableCell>{entity.day}</TableCell>
                      <TableCell>{entity.startTime.substr(0, 5)} - {entity.endTime.substr(0, 5)}</TableCell>
                      <TableCell>{Math.abs((new Date(entity.day + " " + entity.endTime)) - (new Date(entity.day + " " + entity.startTime))) / 36e5} hour</TableCell>
                      <TableCell>{entity.offeringName}</TableCell>
                      <TableCell>{entity.storeBranchName}</TableCell>
                      <TableCell>{<FormattedMessage id={"sessiont.status." + entity.status == "BOOKED" ? entity.status : "CANCELLED"} />}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageNumberChange}
            onChangeRowsPerPage={handlePageSizeChange}
            page={pageNumber}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            labelRowsPerPage={<FormattedMessage id="pagination.label.rowsPerPage" />}
          />
        </CardActions>
        {loading && (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ListingTable;
