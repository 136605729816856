import React, { useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import ErrorFilterResultPlaceholder from "./filters/ErrorFilterResultPlaceholder";
import EmptyFilterResultPlaceholder from "./filters/EmptyFilterResultPlaceholder";
import Frame from "./Frame";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  table: {
    marginTop: theme.spacing(3)
  },
  emptyFilterResult: {
    flex: "1 1 auto"
  },
  errorFilterResult: {
    flex: "1 1 auto"
  },
  progress: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const PreviousAppointments = props => {
  const {
    pageHeader: PageHeader,
    filterForm: FilterForm,
    filterFormInitialValues,
    createFormDialog: CreateFormDialog,
    editFormDialog: EditFormDialog,
    displayDialog: DisplayDialog,
    removeConfirmationDialog: RemoveConfirmationDialog,
    listingTable: ListingTable,
    onFilter,
    api,
    request,
    parent,
    initialPageSize,
    method
  } = props;
  
  let _method = 'get';
  switch(method){
    case 'post':
    case 'put':
      _method = method;
  }

  const classes = useStyles();

  /*
  Status for loading listing table
   */

  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingTableError, setLoadingTableError] = useState(false);
  const [showTableWhileLoading, setShowTableWhileLoading] = useState(false);

  /*
  Filter
   */

  const [filters, setFilters] = React.useState(
    filterFormInitialValues
      ? onFilter
        ? onFilter(filterFormInitialValues)
        : filterFormInitialValues
      : {}
  );
  const handleFilterSubmit = values => {
    setShowTableWhileLoading(false);
    setPageNumber(0);

    setFilters(onFilter ? onFilter(values) : values);
  };

  /*
  Dialog for display information
   */

  const [displayDialogState, setDisplayDialogState] = React.useState({
    open: false
  });

  const handleDisplayDialogClose = React.useCallback(() => {
    setDisplayDialogState(prevState => ({
      ...prevState,
      open: false,
      entity: null
    }));
  }, []);

  const displayDialog = DisplayDialog && (
    <DisplayDialog {...displayDialogState} onClose={handleDisplayDialogClose} />
  );

  /*
  Dialog for remove confirmation
   */

  const [
    removeConfirmationDialogState,
    setRemoveConfirmationDialogState
  ] = React.useState({
    open: false
  });

  const handleRemoveConfirmationDialogClose = React.useCallback(() => {
    setRemoveConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const removeConfirmationDialog = RemoveConfirmationDialog && (
    <RemoveConfirmationDialog
      {...removeConfirmationDialogState}
      onClose={handleRemoveConfirmationDialogClose}
      onSuccess={() => {
        setShowTableWhileLoading(true);

        if (entities.length === 1 && pageNumber > 0) {
          setPageNumber(pageNumber - 1);
        } else {
          setFilters({ ...filters });
        }
      }}
      onBefore={targetEntity => {
        setEntities(
          entities.map(entity => {
            if (entity.id === targetEntity.id) {
              return {
                ...entity,
                deleting: true
              };
            }

            return entity;
          })
        );
      }}
      onAfter={targetEntity => {
        setEntities(
          entities.map(entity => {
            if (entity.id === targetEntity.id) {
              return {
                ...entity,
                deleting: undefined
              };
            }

            return entity;
          })
        );
      }}
      parent={parent}
    />
  );

  /*
  Handle CRUD actions
   */

  const handleView = entity => {
    setDisplayDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const handleRemove = entity => {
    setRemoveConfirmationDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  /*
   Listing table pagination
   */

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize || 10);
  const [totalCount, setTotalCount] = useState(0);

  const handlePageNumberChange = (event, page) => {
    setPageNumber(page);
    setShowTableWhileLoading(true);
  };

  const handlePageSizeChange = event => {
    setPageSize(event.target.value);
    setPageNumber(0);
    setShowTableWhileLoading(true);
  };

  /*
  Table entities
   */

  const [entities, setEntities] = useState([]);

  /*
  Listing table
   */
  const listingTable = (
    <ListingTable
      className={classes.table}
      entities={entities}
      onDelete={handleRemove}
      totalCount={totalCount}
      pageNumber={pageNumber}
      pageSize={pageSize}
      handlePageNumberChange={handlePageNumberChange}
      handlePageSizeChange={handlePageSizeChange}
      loading={loadingTable}
      parent={parent}
    />
  );

  /*
  Load entities
   */

  useLayoutEffect(() => {
    let active = true;

    setLoadingTable(true);
    setLoadingTableError(false);

    const params = {
      ...filters,
      pageNumber: pageNumber,
      pageSize: pageSize
    };

    const result = request
      ? request(params)
      : axios[_method](api, createParams(params));

    result
      .then(response => {
        active && setEntities(response.data["content"] || []);
        active && setTotalCount(response.data["totalElements"]);
        active && setLoadingTableError(false);
      })
      .catch(() => {
        active && setLoadingTableError(true);
      })
      .finally(() => {
        active && setLoadingTable(false);
      });

    return () => {
      active = false;
    };
  }, [api, filters, pageNumber, pageSize, request]);

  return (
    <Frame className={classes.root}>
      <PageHeader parent={parent} />
      {FilterForm && (
        <FilterForm
          initialValues={filterFormInitialValues}
          loading={loadingTable}
          onSubmit={handleFilterSubmit}
          parent={parent}
        />
      )}
      {loadingTableError ? (
        <ErrorFilterResultPlaceholder className={classes.errorFilterResult} />
      ) : loadingTable ? (
        showTableWhileLoading ? (
          listingTable
        ) : (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )
      ) : entities && entities.length > 0 ? (
        listingTable
      ) : (
        <EmptyFilterResultPlaceholder className={classes.emptyFilterResult} />
      )}
      {removeConfirmationDialog}
    </Frame>
  );
};

const createParams = values => {
  const { pageNumber, pageSize, status } = values;

  const params = new URLSearchParams();

  params.append("page", pageNumber);
  params.append("size", pageSize);
  params.append("status", status);

  return params;
};

export default PreviousAppointments;
