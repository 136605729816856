import React, { Suspense, useRef } from "react";
import SideLayout from "./SideLayout";
import { SnackbarProvider } from "notistack";
import LoadingState from "../components/LoadingState";
import AppRoute from "../routes/AppRoute";
import ContentScrollWindowContext from "./ContentScrollWindowContext";

export default function SwitchableLayout() {
  const Layout = SideLayout;

  const contentScrollWindowRef = useRef(null);

  return (
    <Layout scrollWindowRef={contentScrollWindowRef}>
      <Suspense fallback={<LoadingState />}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          maxSnack={1}
          autoHideDuration={3000}
        >
          <ContentScrollWindowContext.Provider value={contentScrollWindowRef}>
            <AppRoute />
          </ContentScrollWindowContext.Provider>
        </SnackbarProvider>
      </Suspense>
    </Layout>
  );
}
