export const SESSION_STATUS_INITIAL = "SESSION_STATUS/INITIAL";
export const SESSION_STATUS_NEW = "SESSION_STATUS/NEW";
export const SESSION_STATUS_COMPLETED_LOGIN = "SESSION_STATUS/COMPLETED_LOGIN";
export const SESSION_STATUS_COMPLETED_RESTORE =
  "SESSION_STATUS/COMPLETED_RESTORE";
export const SESSION_STATUS_LOAD_LOGIN = "SESSION_STATUS/LOAD_LOGIN";
export const SESSION_STATUS_LOAD_FORGET_PASSWORD = "SESSION_STATUS/FORGET_PASSWORD";
export const SESSION_STATUS_LOAD_RESET_PASSWORD = "SESSION_STATUS/RESET_PASSWORD";
export const SESSION_STATUS_LOAD_RESTORE = "SESSION_STATUS/LOAD_RESTORE";
export const SESSION_STATUS_FAILED_UNAUTHENTICATED =
  "SESSION_STATUS/FAILED_UNAUTHENTICATED";
export const SESSION_STATUS_FAILED_GENERAL = "SESSION_STATUS/FAILED_GENERAL";

export function sessionStatusInitial() {
  return {
    type: SESSION_STATUS_INITIAL
  };
}

export function sessionStatusNew() {
  return {
    type: SESSION_STATUS_NEW
  };
}

export function sessionStatusCompletedRestore() {
  return {
    type: SESSION_STATUS_COMPLETED_RESTORE
  };
}

export function sessionStatusCompletedLogin() {
  return {
    type: SESSION_STATUS_COMPLETED_LOGIN
  };
}

export function sessionStatusLoadLogin() {
  return {
    type: SESSION_STATUS_LOAD_LOGIN
  };
}

export function sessionStatusLoadForgetPassword() {
  return {
    type: SESSION_STATUS_LOAD_FORGET_PASSWORD
  };
}

export function sessionStatusLoadResetPassword() {
  return {
    type: SESSION_STATUS_LOAD_RESET_PASSWORD
  };
}

export function sessionStatusLoadRestore() {
  return {
    type: SESSION_STATUS_LOAD_RESTORE
  };
}

export function sessionStatusFailedUnauthenticated() {
  return {
    type: SESSION_STATUS_FAILED_UNAUTHENTICATED
  };
}

export function sessionStatusFailedGeneral() {
  return {
    type: SESSION_STATUS_FAILED_GENERAL
  };
}
