import React, { useEffect, useState } from "react";
import FormikEditForm from "./components/EditForm";
import axios from "axios";
import { HOSTNAME } from "../../constants/api.constant";
import Grid from "@material-ui/core/Grid";
import Frame from "../../components/Frame";
import PageHeader from "../../components/PageHeader";
import { Redirect } from "react-router";
import { FormattedMessage } from "react-intl";
import {
  useApiNotification,
  useNotification
} from "../../hooks/notification.hook";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import qs from "qs";
import { MY_PACKAGES_PATH, BUY_PACKAGE_CANCEL_PATH } from "../../constants/route.constant";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  }
}));

const BuyPackageSuccess = (props) => {
    const classes = useStyles();
  
    const [entities, setEntities] = useState([])
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState("");
    
    const [verified, setVerified] = useState(false);

    const notifyApiError = useApiNotification();
    
    const getData = function(){
      axios
        .get(`${HOSTNAME}/api/packagePlans`)
        .then(response => {
          setEntities(response.data.content);
        })
        .catch(error => {
        })
        .finally(() => {
        });
    }

    useEffect(() => {
        if(props.location.search.indexOf("token=") !== -1){
          axios
            .get(`${HOSTNAME}/api/payment/paypal/success` + props.location.search)
            .then(response => {
              setLoading(true);
              if(response.data === true) window.location.replace(window.location.pathname);
              else window.location.replace(BUY_PACKAGE_CANCEL_PATH);
            })
            .catch(error => {
              setLoading(false);
              
              notifyApiError(
                error.response.status,
                {
                },
                {
                  message: `Unable to process`,
                  variant: "error"
                }
              );
            })
            .finally(() => {
            });
        }else{
          setVerified(true);
        }
        
        return (() => {
        })
    }, [])

    return (
      <Frame>
      {
        verified ? (
          <div>
            <PageHeader
              section="Overview"
              title={<FormattedMessage id="buyPackages.pageTitle.success" />}
              right={
                null
              }
            />
            <p><FormattedMessage id="buyPackages.message.success" /></p>
          </div>
        ) : (
          null
        )
      }
      </Frame>
    )
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.packagePlan && params.append("packagePlan", values.packagePlan);

  return params;
};

export default BuyPackageSuccess;
