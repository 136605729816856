import React, { useState } from "react";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import NavTree from "./NavTree";
import { getNavNodeLeftPadding } from "../utils/navigation.util";
import { useStyles } from "../styles/node.style";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";

const NavPackageNode = props => {
  const { node, className, depth, LeafNodeProps, ...rest } = props;

  const { title, isOpen, icon: Icon, children } = node;

  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);

  const handleToggle = () => {
    setOpen(!open);
  };

  const style = {
    paddingLeft: getNavNodeLeftPadding(depth)
  };

  return (
    <ListItem
      {...rest}
      component='div'
      className={clsx(classes["packageNode"], className)}
      disableGutters
    >
      <Button
        className={classes["packageButton"]}
        onClick={handleToggle}
        style={style}
      >
        {Icon && <Icon className={classes["icon"]} />}
        {title}
        {open ? (
          <ExpandLessIcon className={classes["expandIcon"]} color="inherit" />
        ) : (
          <ExpandMoreIcon className={classes["expandIcon"]} color="inherit" />
        )}
      </Button>
      <Collapse in={open}>
        {children.map(child => {
          return (
            <NavTree
              {...rest}
              key={child.title}
              node={child}
              depth={depth + 1}
              LeafNodeProps={LeafNodeProps}
            />
          );
        })}
      </Collapse>
    </ListItem>
  );
};

export default NavPackageNode;
