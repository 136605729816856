import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING } from "../../../constants/app.constant";
import { INVOICE_PATH } from "../../../constants/route.constant";
import { Button } from "@material-ui/core";
import { HOSTNAME } from "../../../constants/api.constant";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import axios from "axios";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    position: "relative"
  },
  content: {
    padding: 0
  },
  inner: {
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  table: {
    whiteSpace: "nowrap"
  },
  columnAction: {
    minWidth: 120
  }
}));

const ListingTable = props => {
  const {
    className,
    entities,
    onView,
    onEdit,
    onDelete,
    totalCount,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    handlePageSizeChange,
    loading,
    ...rest
  } = props;

  const classes = useStyles();

  const notify = useNotification();
  const notifyApiError = useApiNotification();
  
  const onPrint = function(packagePlan){    
    axios.get(`${HOSTNAME}/api/invoices/${packagePlan.id}`, {
        responseType: 'blob'
    })
    .then(response => {
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    })
    .catch(error => {
        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invoice is not found.`,
              variant: "error"
            }
          },
          {
            message: "Invoice is not found.",
            variant: "error"
          }
        );
    });
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {totalCount} Records found. Page {pageNumber + 1} of{" "}
        {Math.ceil(totalCount / pageSize)}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><FormattedMessage id="packages.packagePlan" /></TableCell>
                    <TableCell><FormattedMessage id="packages.type" /></TableCell>
                    <TableCell><FormattedMessage id="packages.effectiveDate" /></TableCell>
                    <TableCell><FormattedMessage id="packages.expirationDate" /></TableCell>
                    <TableCell><FormattedMessage id="packages.balance" /></TableCell>
                    <TableCell><FormattedMessage id="packages.action" /></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities.map(entity => (
                    <TableRow hover key={entity.id}>
                      <TableCell>{entity && entity.name}</TableCell>
                      <TableCell>
                        {entity &&
                          PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING[entity.type]}
                      </TableCell>
                      <TableCell>
                        {entity.effectiveDate &&
                          moment(entity.effectiveDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {entity.expirationDate &&
                          moment(entity.expirationDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        <Table className={classes.table} size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell><FormattedMessage id="packages.category" /></TableCell>
                              <TableCell><FormattedMessage id="packages.total" /></TableCell>
                              <TableCell><FormattedMessage id="packages.used" /></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {entity.items.map(item => (
                              <TableRow hover key={item.offeringCategoryName}>
                                <TableCell>
                                  {item && item.offeringCategoryName}
                                </TableCell>
                                <TableCell>
                                  {item && item.totalTicketCount}
                                </TableCell>
                                <TableCell>
                                  {item && item.usedTicketCount}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                      <TableCell>
                        <Button color="default" variant="small" onClick={() => onPrint(entity)}>
                          <FormattedMessage id="packages.invoice" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageNumberChange}
            onChangeRowsPerPage={handlePageSizeChange}
            page={pageNumber}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            labelRowsPerPage={<FormattedMessage id="pagination.label.rowsPerPage" />}
          />
        </CardActions>
        {loading && (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ListingTable;
