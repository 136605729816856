import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import securityService from "../../services/security.service";
import axiosConfigService from "../../config/axios.config";
import { Redirect, withRouter } from "react-router";
import { matchLoginPath, matchForgetPasswordPath } from "../../utils/route.util";
import {
  SESSION_STATUS_COMPLETED_RESTORE,
  SESSION_STATUS_COMPLETED_LOGIN,
  SESSION_STATUS_FAILED_GENERAL,
  SESSION_STATUS_FAILED_UNAUTHENTICATED,
  SESSION_STATUS_INITIAL,
  SESSION_STATUS_LOAD_LOGIN,
  SESSION_STATUS_LOAD_FORGET_PASSWORD,
  SESSION_STATUS_LOAD_RESTORE,
  SESSION_STATUS_NEW,
  sessionStatusCompletedRestore,
  sessionStatusCompletedLogin,
  sessionStatusFailedGeneral,
  sessionStatusFailedUnauthenticated,
  sessionStatusLoadLogin,
  sessionStatusLoadForgetPassword,
  sessionStatusLoadRestore,
  sessionStatusNew
} from "./actions/sessionStatus.action";
import LoadingPage from "../../pages/LoadingPage";
import { LOGIN_PATH } from "../../constants/route.constant";
import { HOSTNAME } from "../../constants/api.constant";
import {
  addPersonalSettings,
  removePersonalSettings
} from "./actions/personalSettings.action";

class SecurityFilter extends React.Component {
  componentDidMount() {
    securityService.hasAccessToken()
      ? this.props.sessionStatusLoadRestore()
      : this.props.sessionStatusNew();
    this.loadSessionIfNecessary();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadSessionIfNecessary();
  }

  loadSessionIfNecessary = () => {
    const { status } = this.props;

    // No need to progress session except for the following status
    
    if (
      status !== SESSION_STATUS_LOAD_LOGIN &&
      status !== SESSION_STATUS_LOAD_RESTORE
    ) {
      return;
    }

    axiosConfigService.setAuthorizationHeader(securityService.getAccessToken());

    axios
      .get(`${HOSTNAME}/api/personalSettings`)
      .then(response => {
        this.props.addPersonalSettings(response.data);

        if (status === SESSION_STATUS_LOAD_LOGIN) {
          this.props.sessionStatusCompletedLogin();
        } else {
          this.props.sessionStatusCompletedRestore();
        }
      })
      .catch(error => {
        this.props.removePersonalSettings();
        if (error.response.status === 401) {
          this.props.sessionStatusFailedUnauthenticated();
        } else {
          this.props.sessionStatusFailedGeneral();
        }
      });
  };

  render() {
    const { status, location } = this.props;

    if (!securityService.hasAccessToken()) {
      if (!matchLoginPath(location.pathname) && !matchForgetPasswordPath(location.pathname)) {
        return (
          <Redirect
            to={{
              pathname: LOGIN_PATH,
              state: { from: location }
            }}
          />
        );
      }
    } else if (status === SESSION_STATUS_INITIAL) {
      return <LoadingPage message="Check session status, please wait..." />;
    } else if (
      status === SESSION_STATUS_NEW ||
      status === SESSION_STATUS_FAILED_UNAUTHENTICATED ||
      status === SESSION_STATUS_FAILED_GENERAL
    ) {
      // This is a brand new launch or failed loading, redirect to the login page if the request is not already the LoginPage page

      if (!matchLoginPath(location.pathname) && !matchForgetPasswordPath(location.pathname)) {
        return (
          <Redirect
            to={{
              pathname: LOGIN_PATH,
              state: { from: location }
            }}
          />
        );
      }
    } else if (
      status === SESSION_STATUS_LOAD_LOGIN ||
      status === SESSION_STATUS_LOAD_RESTORE
    ) {
      // Session is being loaded, display a loading page
      return <LoadingPage message="Loading session, please wait..." />;
    } else if (status === SESSION_STATUS_COMPLETED_RESTORE) {
      if (matchLoginPath(location.pathname)) {
        // Redirect to the landing page
        return (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        );
      }
    } else if (status === SESSION_STATUS_COMPLETED_LOGIN) {
      if (matchLoginPath(location.pathname)) {
        const redirectLocation =
          location.state && location.state.from
            ? location.state.from
            : { pathname: "/" };
        return <Redirect to={redirectLocation} />;
      }
    }

    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

const mapStateToProps = ({ session }) => {
  return { status: session.status };
};

const mapDispatchToProps = {
  addPersonalSettings,
  removePersonalSettings,
  sessionStatusNew,
  sessionStatusCompletedLogin,
  sessionStatusCompletedRestore,
  sessionStatusLoadLogin,
  sessionStatusLoadForgetPassword,
  sessionStatusLoadRestore,
  sessionStatusFailedUnauthenticated,
  sessionStatusFailedGeneral
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SecurityFilter)
);
