import React from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { GENDER_OPTIONS } from "../../../constants/app.constant";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikTextField from "../../../components/inputs/FormikTextField";
import PasswordField from "../../../components/inputs/PasswordField";
import FormikAutocomplete from "../../../components/inputs/FormikAutocomplete";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikSwitch from "../../../components/inputs/FormikSwitch";

const useStyles = makeStyles(theme => ({
  sectionLabel: {
    ...theme.typography.h5
  }
}));

export default function AttributeFormDialog(props) {
  const { title, open, loading, onClose, ...rest } = props;

  const classes = useStyles();

  return (
    <FormikFormDialog
      {...rest}
      title={title}
      open={open}
      onClose={onClose}
      loading={loading}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <FormikTextField
              required
              fullWidth
              label="Username"
              name="username"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PasswordField
              fullWidth
              label="Password (Optional)"
              name="password"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormikTextField
              required
              fullWidth
              label="First Name"
              name="firstName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormikTextField
              // required
              fullWidth
              label="Last Name"
              name="lastName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormikTextField
              fullWidth
              label="Middle Name"
              name="middleName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormikTextField
              fullWidth
              label="Nick Name"
              name="nickName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikAutocomplete
              name="gender"
              margin="dense"
              autoHighlight
              options={GENDER_OPTIONS}
              getOptionLabel={option => option && (option.label || "")}
              TextFieldProps={{
                label: "Gender",
                variant: "outlined",
                fullWidth: true
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              autoOk
              disableFuture
              name="birthday"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
              margin="dense"
              fullWidth
              label="Birthday"
              InputAdornmentProps={{ position: "start" }}
              KeyboardButtonProps={{ size: "small" }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikTextField
              fullWidth
              label="Email"
              name="email"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="Mobile Phone"
              name="mobilePhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="Home Phone"
              name="homePhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="Work Phone"
              name="workPhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikTextField
              fullWidth
              label="Street"
              name="street"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="City"
              name="city"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="State or Province"
              name="stateOrProvince"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="Country"
              name="country"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikSwitch
              name="receiveEmailAllowed"
              label="Email Notification"
              description="Enable this will allow clients to receive emails"
              FormLabelProps={{
                className: classes.sectionLabel
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikSwitch
              name="pushNotificationAllowed"
              label="Push Notification"
              description="Enable this will allow clients to receive push notifications"
              FormLabelProps={{
                className: classes.sectionLabel
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikSwitch
              name="unpaidEnrollmentAllowed"
              label="Unpaid Enrollment"
              description="Enable this will allow clients to enroll classes when they have not bought any package plans"
              FormLabelProps={{
                className: classes.sectionLabel
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "At least 3 characters long")
    .max(100, "No more than 150 characters long")
    .matches(
      /^[a-zA-Z0-9_.]*$/,
      "Only alphanumeric characters, dots and underscores allowed"
    )
    .required("Required"),
  password: Yup.string()
    .min(3, "At least 3 characters long")
    .max(20, "No more than 20 characters long"),
  firstName: Yup.string()
    .max(50, "No more than 50 characters long")
    .required("Required"),
  lastName: Yup.string().max(50, "No more than 50 characters long"),
  // .required("Required"),
  middleName: Yup.string().max(50, "No more than 50 characters long"),
  nickName: Yup.string().max(50, "No more than 50 characters long"),
  email: Yup.string().email("Invalid email")
});

export const initialValues = {
  id: null,
  username: "",
  password: "",
  firstName: "",
  lastName: "",
  middleName: "",
  nickName: "",
  gender: null,
  birthday: null,
  email: "",
  mobilePhone: "",
  homePhone: "",
  workPhone: "",
  street: "",
  city: "",
  stateOrProvince: "",
  country: "",
  receiveEmailAllowed: false,
  pushNotificationAllowed: false,
  unpaidEnrollmentAllowed: false
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.username && params.append("username", values.username);
  values.firstName && params.append("firstName", values.firstName);
  values.lastName && params.append("lastName", values.lastName);
  values.middleName && params.append("middleName", values.middleName);
  values.nickName && params.append("nickName", values.nickName);
  values.email && params.append("email", values.email);
  values.mobilePhone && params.append("mobilePhone", values.mobilePhone);
  values.homePhone && params.append("homePhone", values.homePhone);
  values.workPhone && params.append("workPhone", values.workPhone);
  values.street && params.append("street", values.street);
  values.city && params.append("city", values.city);
  values.stateOrProvince &&
    params.append("stateOrProvince", values.stateOrProvince);
  values.country && params.append("country", values.country);
  values.birthday &&
    params.append("birthday", values.birthday.format("YYYY-MM-DD"));
  values.password && params.append("password", values.password);
  values.receiveEmailAllowed &&
    params.append("receiveEmailAllowed", values.receiveEmailAllowed);
  values.pushNotificationAllowed &&
    params.append("pushNotificationAllowed", values.pushNotificationAllowed);
  values.unpaidEnrollmentAllowed &&
    params.append("unpaidEnrollmentAllowed", values.unpaidEnrollmentAllowed);

  if (values.gender && values.gender.value) {
    params.append("gender", values.gender.value);
  }

  return params;
};
