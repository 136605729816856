import React from "react";
import NavLeaf from "./NavLeafNode";
import NavPackageNode from "./NavPackageNode";

export default function NavTree(props) {
  const { node, depth, LeafNodeProps, ...rest } = props;

  if (!node.children) {
    return <NavLeaf {...LeafNodeProps} {...rest} node={node} depth={depth} />;
  } else {
    return (
      <NavPackageNode
        {...rest}
        node={node}
        depth={depth}
        LeafNodeProps={LeafNodeProps}
      />
    );
  }
}
