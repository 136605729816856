import React, { useState } from "react";
import clsx from "clsx";
import moment from "moment";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Hidden,
  Typography,
  Tooltip,
  ButtonGroup,
  IconButton,
  Button
} from "@material-ui/core";
import ViewComfyOutlinedIcon from "@material-ui/icons/ViewComfyOutlined";
import ViewWeekOutlinedIcon from "@material-ui/icons/ViewWeekOutlined";
import ViewDayOutlinedIcon from "@material-ui/icons/ViewDayOutlined";
import { FormattedMessage } from "react-intl";
import localeService from "../../../services/locale.service";

const useStyles = makeStyles(() => ({
  root: {}
}));

const Toolbar = props => {
  const {
    date,
    view,
    onDatePrev,
    onDateNext,
    onEventAdd,
    onViewChange,
    onDateToday,
    className,
    ...rest
  } = props;
  
  const present = moment().startOf("day");

  const classes = useStyles();
  
  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);

  const viewOptions = [
    {
      label: <FormattedMessage id="scheduleAppointments.dailyLabel" />,
      value: "DAILY",
      icon: ViewDayOutlinedIcon
    },
    {
      label: <FormattedMessage id="scheduleAppointments.weeklyLabel" />,
      value: "WEEKLY",
      icon: ViewWeekOutlinedIcon
    },
    {
      label: <FormattedMessage id="scheduleAppointments.monthlyLabel" />,
      value: "MONTHLY",
      icon: ViewComfyOutlinedIcon
    }
  ];
  
  let dayRange = moment(date).format("D/M/YYYY")//.format(locale == "en" ? "dddd, DD MMMM YYYY" : "LL[（]dd[）]")
  if(view === "WEEKLY") {
    dayRange = moment(date).format("D/M/YYYY") + " - " + moment(date).add(7, 'days').format("D/M/YYYY")//.format(locale == "en" ? "DD MMMM YYYY" : "LL") + " - " + moment(date).add(7, 'days').format(locale == "en" ? "DD MMMM YYYY" : "LL");
  } else if(view === "MONTHLY"){
    dayRange = moment(date).format("M/YYYY");//moment(date).format(locale == "en" ? "MMMM YYYY" : "YYYY年M月");
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between" spacing={3}>
        <Grid item>
          <ButtonGroup>
            <Button onClick={onDatePrev} disabled={moment(date).isSameOrBefore(present)}><FormattedMessage id="scheduleAppointments.prev" /></Button>
            <Button onClick={onDateToday}><FormattedMessage id="scheduleAppointments.today" /></Button>
            <Button onClick={onDateNext}><FormattedMessage id="scheduleAppointments.next" /></Button>
          </ButtonGroup>
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Typography variant="h3">
              {dayRange}
            </Typography>
          </Grid>
          <Grid item>
            {viewOptions.map(viewOption => {
              const Icon = viewOption.icon;

              return (
                <Tooltip key={viewOption.value} title={viewOption.label}>
                  <IconButton
                    color={viewOption.value === view ? "secondary" : "default"}
                    onClick={() => onViewChange(viewOption.value)}
                  >
                    <Icon />
                  </IconButton>
                </Tooltip>
              );
            })}
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  date: PropTypes.any.isRequired,
  onDateNext: PropTypes.func,
  onDatePrev: PropTypes.func,
  onDateToday: PropTypes.func,
  onEventAdd: PropTypes.func,
  onViewChange: PropTypes.func,
  view: PropTypes.string.isRequired
};

export default Toolbar;
