import { DEFAULT_LOCALE, MESSAGES } from "../constants/locale.constant";
import { formatToHyphen } from "../utils/locale.util";

class localeService {
  detectLocaleIfPossible = () => {
    let locale = window.sessionStorage.getItem("locale") ||
      (navigator.languages && navigator.languages[0]) || navigator.language;

    locale = formatToHyphen(
      locale && this.isLocaleSupported(locale) ? locale : DEFAULT_LOCALE
    );
    
    window.sessionStorage.setItem("locale", locale);
    
    return locale;
  };

  isLocaleSupported(locale) {
    return Object.keys(MESSAGES).includes(locale);
  }
}

let instance = new localeService();

export default instance;
