import React, { useState } from "react";
import moment from "moment";
import numeral from "numeral";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import PerfectScrollbar from "react-perfect-scrollbar";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import { Link } from "react-router-dom";
import HistoryIcon from "@material-ui/icons/History";
import { FormattedMessage } from "react-intl";
import {
  retrieveInstructorName,
  retrieveSessionStatus
} from "../../../utils/app.util";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "0 !important"
  },
  card: {
    position: "relative"
  },
  content: {
    padding: 0
  },
  inner: {
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  table: {
    whiteSpace: "nowrap"
  },
  columnAction: {
    minWidth: 120
  }
}));

const ListingTable = props => {
  const {
    className,
    entities,
    onCancel,
    onWaitListConfirm,
    totalCount,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    handlePageSizeChange,
    loading,
    ...rest
  } = props;

  const classes = useStyles();

  const {
    isReadCustomerUserAllowed,
    isEditCustomerUserAllowed,
    isDeleteCustomerUserAllowed
  } = useAccessControl();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><FormattedMessage id="appointments.day" /></TableCell>
                    <TableCell><FormattedMessage id="appointments.time" /></TableCell>
                    <TableCell><FormattedMessage id="appointments.duration" /></TableCell>
                    <TableCell><FormattedMessage id="appointments.service" /></TableCell>
                    <TableCell><FormattedMessage id="appointments.location" /></TableCell>
                    <TableCell><FormattedMessage id="appointments.status" /></TableCell>
                    <TableCell className={classes.columnAction} align="left">
                      <FormattedMessage id="appointments.action" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities.map(entity => {

                    const startTimeMoment = moment(entity.startTime, "HH:mm");
                    const endTimeMoment = moment(entity.endTime, "HH:mm");

                    const startTimeText = startTimeMoment.format("HH:mm");
                    const endTimeText = endTimeMoment.format("HH:mm");
                    
                    const sessionStatus = retrieveSessionStatus(entity);
                    console.log(entity);
                    console.log(sessionStatus);

                    const instructorName = retrieveInstructorName(entity.instructor);

                    const duration = numeral(
                      endTimeMoment.diff(startTimeMoment, "hours", true)
                    ).format("0.0");
                    
                    return (
                      <TableRow hover key={entity.id}>
                        <TableCell>{entity.day}</TableCell>
                        <TableCell>{`${startTimeText} - ${endTimeText}`}</TableCell>
                        <TableCell>{duration} hour{duration >= 2 ? "s" : ""}</TableCell>
                        <TableCell>{entity.offeringName}</TableCell>
                        <TableCell>{entity.storeBranchName}</TableCell>
                        <TableCell>{<FormattedMessage id={"session.status." + entity.status} />}</TableCell>
                        <TableCell>
                          {sessionStatus.canCancel && (
                            <Button
                              color="default"
                              size="small"
                              onClick={() => onCancel(entity)}
                            >
                              <FormattedMessage id="appointments.cancel" />
                            </Button>
                          )}
                          {sessionStatus.canWaitListConfirm && (
                            <Button
                              color="default"
                              size="small"
                              onClick={() => onWaitListConfirm(entity)}
                            >
                              <FormattedMessage id="appointments.confirm" />
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onChangePage={handlePageNumberChange}
            onChangeRowsPerPage={handlePageSizeChange}
            page={pageNumber}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            labelRowsPerPage={<FormattedMessage id="pagination.label.rowsPerPage" />}
          />
        </CardActions>
        {loading && (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ListingTable;
