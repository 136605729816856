import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  progress: {
    marginBottom: 20
  }
}));

export default function LoadingPage(props) {
  const classes = useStyles();

  const { message = "Loading..." } = props;

  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.progress}
        color="primary"
        size={50}
      />
      <Typography variant="body1" color="textSecondary">
        {message}
      </Typography>
    </div>
  );
}
