import React from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import { HOSTNAME } from "../../../constants/api.constant";
import axios from "axios";

const WaitListConfirmationDialog = props => {
  const { open, onClose, onBefore, onAfter, onSuccess, entity } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleConfirm = () => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity.id);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Removing enrollment of "${entity.offeringName}"`);
    }, PROGRESS_TIME_LIMIT);

    const params = {
      transcript: (entity.transcript ? entity.transcript.id : entity.id)
    };

    axios
      .post(`${HOSTNAME}/api/actions/transcripts/confirm`, createParams(params))
      .then(() => {
        notify(`Enrollment of "${entity.offeringName}" is confirmed`, "success");

        onSuccess();
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to confirm enrollment of ${entity.offeringName}`,
              variant: "error"
            }
          },
          {
            message: `Unable to confirm enrollment of "${entity.offeringName}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity.id);

        clearTimeout(displayProgressTimeoutKey);
      });
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title="Confirm Enrollment?"
      message={
        <React.Fragment>
          Enrollment of <b>{entity.offeringName}</b> on {entity.day} at {entity.startTime} is
          going to be confirmed.
        </React.Fragment>
      }
      onConfirm={handleConfirm}
    />
  );
};

const createParams = values => {
  const { transcript } = values;

  const params = new URLSearchParams();

  params.append("transcript", transcript);

  return params;
};

export default WaitListConfirmationDialog;