import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  right: {},
  breadcrumbs: {
    display: 'none',
    marginBottom: theme.spacing(2)
  },
  subTitle: {
    marginTop: theme.spacing(1)
  }
}));

const PageHeader = props => {
  const {
    className,
    section,
    title,
    subTitle,
    breadcrumbs,
    right,
    rightClassName,
    disableTypography,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.header}>
        <div>
          <Breadcrumbs className={classes.breadcrumbs}>
            <Typography variant="overline">{section}</Typography>
            {breadcrumbs &&
              breadcrumbs.map(item => (
                <Link to={item.link} key={item.link}>
                  <Typography variant="overline">
                    {_.truncate(item.name, {
                      length: 30,
                      separator: " "
                    })}
                  </Typography>
                </Link>
              ))}
          </Breadcrumbs>
          {disableTypography ? (
            title
          ) : (
            <Typography variant="h2">{title}</Typography>
          )}
          <div className={classes.subTitle}>{subTitle}</div>
        </div>
        {right && (
          <div className={clsx(classes.right, rightClassName)}>{right}</div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
