import {
  ADD_PERSONAL_SETTINGS,
  REMOVE_PERSONAL_SETTINGS
} from "../actions/personalSettings.action";

const initialState = {};

const personalSettings = function(state = initialState, action) {
  switch (action.type) {
    case ADD_PERSONAL_SETTINGS: {
      return action.payload;
    }
    case REMOVE_PERSONAL_SETTINGS: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default personalSettings;
