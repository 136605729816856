import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import ProblemIcon from "../../../../icons/ProblemIcon";
import red from "@material-ui/core/colors/red";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: red["200"]
  },
  text: {
    marginTop: theme.spacing(2)
  },
  icon: {
    width: 120,
    height: "auto",
    fill: red["200"]
  }
}));

const ErrorFilterResultPlaceholder = props => {
  const { className } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <ProblemIcon className={classes.icon} />
      <Typography
        className={classes.text}
        align="left"
        variant="h6"
        color="inherit"
      >
        <p>
          We are sorry that request failed due to server error.
          <br /> Please try again later on or contact the administrator.
        </p>
      </Typography>
    </div>
  );
};

export default ErrorFilterResultPlaceholder;
