/*
 When search buttons are clicked very frequently by very impatient users, a latency is used for querying results.
 The unit is ms.
 */
export const SEARCH_BUTTON_REFRESH_LATENCY = 500;

/*
When an API request is in progress, the time limit before showing a progress snackbar
 */
export const PROGRESS_TIME_LIMIT = 1500;

export const UPCOMING_OFFERING_SCHEDULE_SESSION_DAY_SIZE = 3;
export const UPCOMING_OFFERING_SCHEDULE_SESSION_DAY_LIMIT_COUNT = 60;
