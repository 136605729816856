import React, { useEffect, useState } from "react";
import FormikEditForm from "./components/EditForm";
import axios from "axios";
import { HOSTNAME } from "../../constants/api.constant";
import Grid from "@material-ui/core/Grid";
import Frame from "../../components/Frame";
import PageHeader from "../../components/PageHeader";
import { Redirect } from "react-router";
import {
  useApiNotification,
  useNotification
} from "../../hooks/notification.hook";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import { PROGRESS_TIME_LIMIT } from "../../config/app.config";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  }
}));

const BuyPackage = () => {
    const classes = useStyles();
  
    const [entities, setEntities] = useState([])
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState("");

    const notify = useNotification();
    const notifyApiError = useApiNotification();
    
    const getData = function(){
      axios
        .get(`${HOSTNAME}/api/packagePlans`)
        .then(response => {
          setEntities(response.data.content);
        })
        .catch(error => {
        })
        .finally(() => {
        });
    }
    
    const doBuy = function(packagePlanId){
      setLoading(true);
      
      const displayProgressTimeoutKey = setTimeout(() => {
        notify(`Processing`);
      }, PROGRESS_TIME_LIMIT);
      
      axios
        .post(`${HOSTNAME}/api/payment/paypal/pay`, createParams({packagePlan: packagePlanId}))
        .then(response => {
          setLoading(true);
          window.location.href = response.data;
        })
        .catch(error => {
          clearTimeout(displayProgressTimeoutKey);
          setLoading(false);
          
          notifyApiError(
            error.response.status,
            {
            },
            {
              message: `Unable to process`,
              variant: "error"
            }
          );
        })
        .finally(() => {
        });
    }

    useEffect(() => {
        getData();
        
        return (() => {
        })
    }, [])
    
    if (redirect) {
        return <Redirect to={redirect}/>
    }

    return (
      <Frame>
        <PageHeader
          section="Overview"
          title={<FormattedMessage id="navigation.buyPackagePlan" />}
          right={
            null
          }
        />
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell><FormattedMessage id="packages.packagePlan" /></TableCell>
              <TableCell><FormattedMessage id="packages.type" /></TableCell>
              <TableCell><FormattedMessage id="packages.validityPeriod" /></TableCell>
              <TableCell><FormattedMessage id="packages.location" /></TableCell>
              <TableCell><FormattedMessage id="packages.balance" /></TableCell>
              <TableCell><FormattedMessage id="packages.price" /></TableCell>
              <TableCell><FormattedMessage id="packages.action" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {entities.map((entity) => {
              return (
                <TableRow key={entity.id}>
                  <TableCell>{entity.name}</TableCell>
                  <TableCell>{entity.type}</TableCell>
                  <TableCell>{entity.validityPeriod}</TableCell>
                  <TableCell>{entity.applicableStoreBranch.name}</TableCell>
                  <TableCell>
                    <Table className={classes.table} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Category</TableCell>
                          <TableCell>Tickets</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {entity.items.map((item) => {
                          return (
                            <TableRow>
                              <TableCell>{item.offeringCategory.name}</TableCell>
                              <TableCell>{item.ticketCount}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell>${entity.price}</TableCell>
                  <TableCell>
                    <Button
                      color="default"
                      size="small"
                      onClick={() => doBuy(entity.id)}
                      disabled={loading}
                    >
                      <FormattedMessage id="packages.buy" />
                    </Button>
                  </TableCell>
                </TableRow>
              )
          })}
          </TableBody>
        </Table>
      </Frame>
    )
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.packagePlan && params.append("packagePlan", values.packagePlan);

  return params;
};

export default BuyPackage;
