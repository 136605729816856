import React, { useEffect, useState } from "react";
import FormikEditForm from "./components/EditForm";
import axios from "axios";
import { HOSTNAME } from "../../constants/api.constant";
import Grid from "@material-ui/core/Grid";
import Frame from "../../components/Frame";
import PageHeader from "../../components/PageHeader";
import { Redirect } from "react-router";
import {
  useApiNotification,
  useNotification
} from "../../hooks/notification.hook";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import qs from "qs";
import { MY_PACKAGES_PATH } from "../../constants/route.constant";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  }
}));

const BuyPackageCancel = (props) => {
    const classes = useStyles();
  
    const [entities, setEntities] = useState([])
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState("");

    const notifyApiError = useApiNotification();
    
    useEffect(() => {        
        setRedirect(MY_PACKAGES_PATH);
        
        return (() => {
        })
    }, [])
    
    if (redirect) {
        return <Redirect to={redirect}/>
    }

    return (
      null
    )
};

export default BuyPackageCancel;
