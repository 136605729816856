import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { Formik, useFormikContext } from "formik";
import oauth2Service from "../../../services/oauth2.service";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { sessionStatusLoadResetPassword } from "../../../security/SecurityFilter/actions/sessionStatus.action";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import InputAdornment from "@material-ui/core/InputAdornment";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FormikTextField from "../../../components/inputs/FormikTextField";
import { LOGIN_PATH, RESET_PASSWORD_PATH } from "../../../constants/route.constant";
import { HOSTNAME } from "../../../constants/api.constant";
import axios from "axios";
import qs from "qs";
import { Redirect, withRouter, browserHistory } from "react-router";
import getAllFieldErrors from "../../../utils/api.util";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 3)
  },
  field: {
    marginBottom: theme.spacing(2)
  }
}));

if(RESET_PASSWORD_PATH == window.location.pathname && !window.sessionStorage.getItem("email")){
  window.location = LOGIN_PATH
}

const ResetPasswordFormSchema = Yup.object().shape({
  email: Yup.string().email(<FormattedMessage id="resetPassword.form.error.email" />)
    .required(<FormattedMessage id="resetPassword.form.error.required" />),
  password: Yup.string()
    .min(8, <FormattedMessage id="resetPassword.form.error.passwordMin" />)
    .max(20, <FormattedMessage id="resetPassword.form.error.passwordMax" />),
  confirmedPassword: Yup.string().when('password', (value, schema) => {
    if (!value) {
      return schema.notRequired()
    }
    return schema.oneOf([Yup.ref('password')], <FormattedMessage id="resetPassword.form.error.passwordNotMatch" />).required(<FormattedMessage id="resetPassword.form.error.passwordNotMatch" />)
  })
});

function ResetPasswordForm(props) {
  const { handleSubmit, isSubmitting, isValid, dirty } = useFormikContext();

  const classes = useStyles();

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <p style={{marginBottom: '10px'}}><FormattedMessage id="resetPassword.alert.passCode" /></p>
      <FormikTextField
        className={classes.field}
        id="email"
        name="email"
        variant="outlined"
        fullWidth
        label={<FormattedMessage id="resetPassword.form.email" />}
        autoComplete="false"
        style={{display:"none"}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <LockOpenIcon color="action" />
            </InputAdornment>
          )
        }}
      />
      <FormikTextField
        className={classes.field}
        id="passCode"
        name="passCode"
        variant="outlined"
        fullWidth
        label={<FormattedMessage id="resetPassword.form.passcode" />}
        autoComplete="false"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <LockOpenIcon color="action" />
            </InputAdornment>
          )
        }}
      />
      <FormikTextField
        className={classes.field}
        id="password"
        name="password"
        type="password"
        variant="outlined"
        fullWidth
        label={<FormattedMessage id="resetPassword.form.password" />}
        autoComplete="false"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <LockOpenIcon color="action" />
            </InputAdornment>
          )
        }}
      />
      <FormikTextField
        className={classes.field}
        id="confirmedPassword"
        name="confirmedPassword"
        type="password"
        variant="outlined"
        fullWidth
        label={<FormattedMessage id="resetPassword.form.passwordConfirmation" />}
        autoComplete="false"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <LockOpenIcon color="action" />
            </InputAdornment>
          )
        }}
      />
      <Box position="relative">
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting || !isValid || !dirty}
        >
          {!isSubmitting ? (
            <FormattedMessage id="resetPassword.form.button.submit" />
          ) : (
            <FormattedMessage id="resetPassword.form.button.loading" />
          )}
        </Button>
      </Box>
      <Grid container>
        <Grid item xs>
          <Link href={LOGIN_PATH} variant="body1">
            <FormattedMessage id="resetPassword.login" />
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}

function FormikResetPasswordForm() {
  const [submitted, setSubmitted] = useState(false);
  
  if(submitted){
    setTimeout(function(){window.location.href = LOGIN_PATH}, 6000);
  }
  
  return (
    <div>
    {
      submitted ? (
        <div>
          <p><FormattedMessage id="resetPassword.message.redirect" /></p>
        </div>
      ) : (
        <Formik
          validationSchema={ResetPasswordFormSchema}
          onSubmit={(values, formikActions) => {
            return axios
              .post(
                `${HOSTNAME}/public/forgetPassword/resetPassword`,
                qs.stringify({
                  ...values
                })
              )
              .then(response => {
                setSubmitted(true);
              }).catch(error => {
                if (error.response.status === 400) {
                  formikActions.setErrors(getAllFieldErrors(error.response));
                } else if (error.response.status === 500) {
                  formikActions.setFieldError(
                    "password",
                    <FormattedMessage id="resetPassword.form.error.auth.server" />
                  );
                } else {
                  formikActions.setFieldError(
                    "password",
                    <FormattedMessage id="resetPassword.form.error.auth.unknown" />
                  );
                }

                formikActions.setSubmitting(false);
              });
          }}
          initialValues={{ email: window.sessionStorage.getItem("email") }}
          component={ResetPasswordForm}
        />
      )
    }
    </div>
  );
}

export default connect(
  null,
  { sessionStatusLoadResetPassword }
)(FormikResetPasswordForm);
