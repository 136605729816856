import React, { useState } from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import { HOSTNAME } from "../../../constants/api.constant";
import axios from "axios";
import localeService from "../../../services/locale.service";
import { FormattedMessage } from "react-intl";

const CancelConfirmationDialog = props => {
  const { open, onClose, onBefore, onAfter, onSuccess, entity } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();
  
  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);

  const handleRemove = () => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity.id);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Removing enrollment of "${entity.offeringName}"`);
    }, PROGRESS_TIME_LIMIT);

    const params = {
      transcript: entity.transcript.id
    };

    axios
      .post(`${HOSTNAME}/api/actions/transcripts/cancel`, createParams(params))
      .then(() => {
        notify(`Enrollment of "${entity.offeringName}" removed`, "success");

        onSuccess();
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to remove enrollment of ${entity.offeringName}`,
              variant: "error"
            }
          },
          {
            message: `Unable to remove enrollment of "${entity.offeringName}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity.id);

        clearTimeout(displayProgressTimeoutKey);
      });
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={<FormattedMessage id="scheduleAppointments.cancelEnrollment" />}
      message={
        <React.Fragment>
          <FormattedMessage id="scheduleAppointments.cancelEnrollmentMessage" />
        </React.Fragment>
      }
      onConfirm={handleRemove}
    />
  );
};

const createParams = values => {
  const { transcript } = values;

  const params = new URLSearchParams();

  params.append("transcript", transcript);

  return params;
};

export default CancelConfirmationDialog;