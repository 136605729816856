import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SelectPurchasedPackagePlanForm from "./SelectPurchasedPackagePlanForm";
import { makeStyles, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FormDialog from "../../../components/dialogs/FormDialog";
import { useFormikContext } from "formik";
import FormikTextField from "../../../components/inputs/FormikTextField";

const useStyles = makeStyles({
  form: {
    width: 836
  }
});

export default function(props) {
  const {
    values,
    open,
    onOpen,
    onClose,
    title,
    loading,
    purchasedPackagePlans,
    onPurchasedPackagePlanSelect,
    selectedPurchasedPackagePlan,
    onSubmit,
    onReset,
    dirty,
    isValid,
    isSubmitting,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <FormDialog
      {...rest}
      title={title}
      open={open}
      values={values}
      loading={loading}
      onEnter={onOpen}
      onClose={onClose}
      paperClassName={classes.form}
      onSubmit={onSubmit}
      onReset={onReset}
      submitButtonDisabled={!dirty || !isValid || isSubmitting}
      resetButtonDisabled={(!dirty && isValid) || isSubmitting}
    >
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SelectPurchasedPackagePlanForm
              purchasedPackagePlans={purchasedPackagePlans}
              onSelect={onPurchasedPackagePlanSelect}
              selected={selectedPurchasedPackagePlan}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container direction="column" spacing={1}>
            {/*<Grid item xs={12}>
              <FormikTextField
                fullWidth
                label="Date"
                name="date"
                variant="outlined"
                margin="dense"
                initialValue=""
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                label="Time Slot"
                name="time-slot"
                variant="outlined"
                margin="dense"
                initialValue=""
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                label="Location"
                name="location"
                variant="outlined"
                margin="dense"
                initialValue=""
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                fullWidth
                label="Service"
                name="service"
                variant="outlined"
                margin="dense"
                initialValue=""
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>*/}
          </Grid>
        </Grid>
      </Grid>
    </FormDialog>
  );
}