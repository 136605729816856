import React, { useState } from "react";
import { Button } from "@material-ui/core";
import PageHeader from "../../../components/PageHeader";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import localeService from "../../../services/locale.service";
import { FormattedMessage } from "react-intl";

const UpcomingPageHeader = props => {
  const { onCreate } = props;

  return (
    <PageHeader
      title={<FormattedMessage id="appointments.upcomingAppointments" />}
      right={
        null
      }
    />
  );
};

export default UpcomingPageHeader;
