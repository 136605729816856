import React from "react";
import MyPackagesTabs from "./components/MyPackagesTabs";


const MyPackages = () => {
  return (
    <div>
      <MyPackagesTabs />
    </div>
  );
};

export default MyPackages;
