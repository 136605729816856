import React from "react";

const ProblemIcon = props => (
  <svg height="1em" viewBox="0 -30 512 512" width="1em" {...props}>
    <path d="M496.137 291.48l-151-242C325.805 18.496 292.48 0 256 0s-69.805 18.496-89.137 49.48l-151 242C5.484 308.113 0 327.313 0 347c0 57.898 47.102 105 105 105h302c57.898 0 105-47.102 105-105 0-19.688-5.484-38.887-15.863-55.52zM407 422H105c-41.355 0-75-33.645-75-75 0-14.066 3.914-27.773 11.316-39.637l151-242C206.133 43.22 229.937 30 256 30s49.867 13.219 63.684 35.363l151 242C478.086 319.227 482 332.933 482 347c0 41.355-33.645 75-75 75zm0 0" />
    <path d="M294.234 81.246C285.934 67.941 271.641 60 256 60s-29.934 7.941-38.234 21.242l-150.996 242C62.277 330.437 60 338.434 60 347c0 24.813 20.188 45 45 45h302c24.813 0 45-20.188 45-45 0-8.566-2.277-16.559-6.766-23.758zM407 362H105c-8.27 0-15-6.73-15-15 0-2.906.727-5.48 2.219-7.879l151-241.996C246.004 92.665 250.78 90 256 90s10 2.664 12.781 7.129l151 241.996C421.273 341.52 422 344.094 422 347c0 8.27-6.73 15-15 15zm0 0" />
    <path d="M241 165h30v106h-30zm0 0M241 301h30v30h-30zm0 0" />
  </svg>
);

export default ProblemIcon;