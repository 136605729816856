import React, { useEffect, useState } from "react";
import FormikEditForm from "./components/EditForm";
import axios from "axios";
import { HOSTNAME } from "../../constants/api.constant";

const MyInfo = () => {
  
  return (
    <FormikEditForm>
    </FormikEditForm>
  );
};

export default MyInfo;
