import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import { Formik, useFormikContext } from "formik";
import oauth2Service from "../../../services/oauth2.service";
import React, { useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { sessionStatusLoadLogin } from "../../../security/SecurityFilter/actions/sessionStatus.action";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FormikTextField from "../../../components/inputs/FormikTextField";
import PasswordField from "../../../components/inputs/PasswordField";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import { LOGIN_PATH, ACTIVATION_PATH } from "../../../constants/route.constant";
import { HOSTNAME } from "../../../constants/api.constant";
import { GENDER_OPTIONS } from "../../../constants/app.constant";
import FormikAutocomplete from "../../../components/inputs/FormikAutocomplete";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import axios from "axios";
import { Redirect, Route, Switch } from "react-router";
import { useIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 3)
  },
  field: {
    marginBottom: theme.spacing(2)
  }
}));

export const validationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, <FormattedMessage id="profile.form.error.usernameMin" />)
    .max(100, <FormattedMessage id="profile.form.error.usernameMax" />)
    .matches(
      /^[a-zA-Z0-9_.]*$/,
     <FormattedMessage id="profile.form.error.usernameFormat" />
    )
    .required(<FormattedMessage id="profile.form.error.required" />),
  password: Yup.string()
    .min(8, <FormattedMessage id="profile.form.error.passwordMin" />)
    .max(20, <FormattedMessage id="profile.form.error.passwordMax" />)
    .required(<FormattedMessage id="profile.form.error.required" />),
  passwordConfirmation: Yup.string().when('password', (value, schema) => {
        if (!value) {
            return schema.notRequired()
        }
        return schema.oneOf([Yup.ref('password')], <FormattedMessage id="profile.form.error.passwordNotMatch" />).required(<FormattedMessage id="profile.form.error.passwordNotMatch" />)
    }),
  firstName: Yup.string()
    .max(50, <FormattedMessage id="profile.form.error.nameMax" />)
    .required(<FormattedMessage id="profile.form.error.required" />),
  lastName: Yup.string().max(50, <FormattedMessage id="profile.form.error.nameMax" />)
    .required(<FormattedMessage id="profile.form.error.required" />),
  middleName: Yup.string().max(50, <FormattedMessage id="profile.form.error.nameMax" />),
  nickName: Yup.string().max(50, <FormattedMessage id="profile.form.error.nameMax" />),
  gender: Yup.string()
    .required(<FormattedMessage id="profile.form.error.required" />),
  birthday: Yup.date(<FormattedMessage id="profile.form.error.required" />)
    .required(<FormattedMessage id="profile.form.error.required" />),
  email: Yup.string().email(<FormattedMessage id="profile.form.error.email" />)
    .required(<FormattedMessage id="profile.form.error.required" />),
  mobilePhone: Yup.string()
    .min(8, <FormattedMessage id="profile.form.error.mobilePhoneNumber" />)
    .max(8, <FormattedMessage id="profile.form.error.mobilePhoneNumber" />)
    .required(<FormattedMessage id="profile.form.error.required" />),
  street: Yup.string().required(<FormattedMessage id="profile.form.error.required" />),
  city: Yup.string().required(<FormattedMessage id="profile.form.error.required" />),
  stateOrProvince: Yup.string().required(<FormattedMessage id="profile.form.error.required" />),
  country: Yup.string().required(<FormattedMessage id="profile.form.error.required" />)
});

function CreateAccountForm() {
  const { handleSubmit, isSubmitting, isValid, dirty } = useFormikContext();

  const classes = useStyles();
  
  const intl = useIntl();

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.username" />}
              name="username"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikTextField
              required
              fullWidth
              label="Email"
              name="email"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PasswordField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.password" />}
              name="password"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PasswordField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.passwordConfirm" />}
              name="passwordConfirmation"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.firstName" />}
              name="firstName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.lastName" />}
              name="lastName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label={<FormattedMessage id="profile.form.middleName" />}
              name="middleName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label={<FormattedMessage id="profile.form.nickName" />}
              name="nickName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikAutocomplete
              name="gender"
              margin="dense"
              autoHighlight
              options={GENDER_OPTIONS}
              getOptionLabel={option => option && (option.label || "")}
              TextFieldProps={{
                required: true,
                label: <FormattedMessage id="profile.form.gender" />,
                variant: "outlined",
                fullWidth: true
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              required
              autoOk
              disableFuture
              name="birthday"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
              margin="dense"
              fullWidth
              label={<FormattedMessage id="profile.form.birthday" />}
              InputAdornmentProps={{ position: "start" }}
              KeyboardButtonProps={{ size: "small" }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.mobilePhone" />}
              name="mobilePhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label={<FormattedMessage id="profile.form.homePhone" />}
              name="homePhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label={<FormattedMessage id="profile.form.workPhone" />}
              name="workPhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.street" />}
              name="street"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.city" />}
              name="city"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.stateProvince" />}
              name="stateOrProvince"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.country" />}
              name="country"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikSwitch
              name="receiveEmailAllowed"
              label={<FormattedMessage id="profile.form.emailNotification" />}
              description={<FormattedMessage id="profile.form.emailNotificationEnable" />}
              FormLabelProps={{
                className: classes.sectionLabel
              }}
            />
          </Grid>
        </Grid>
        <Box position="relative">
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting || !isValid || !dirty}
          >
            {!isSubmitting ? (
              <FormattedMessage id="profile.form.button.submit" />
            ) : (
              <FormattedMessage id="profile.form.button.loading" />
            )}
          </Button>
        </Box>
        <Grid container>
          <Grid item xs>
            <Link href={LOGIN_PATH} variant="body1">
              <FormattedMessage id="register.login" />
            </Link>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </form>
  );
}

function FormikLoginForm(props) {  
  const [submitted, setSubmitted] = useState(false);
  
  return (
    <div>
    {
      submitted ? (
        <div>
          <p><FormattedMessage id="register.message.thankYou" /></p>
          <p><FormattedMessage id="register.message.activationInstruction" /></p>
        </div>
      ) : (
        <Formik
          validationSchema={validationSchema}
          onSubmit={(values, formikActions) => {
            axios
              .post(`${HOSTNAME}/public/accounts/register`, createParams(values))
              .then(response => {
                setSubmitted(true);
              })
              .catch(error => {
              })
              .finally(() => {
                formikActions.setSubmitting(false);
              });
              
            return false;
          }}
          initialValues={initialValues}
          component={CreateAccountForm}
        />
      )
    }
    </div>
  );
}

export let initialValues = {
  username: "",
  password: "",
  passwordConfirmation: "",
  firstName: "",
  lastName: "",
  middleName: "",
  nickName: "",
  gender: "",
  birthday: "",
  email: "",
  mobilePhone: "",
  homePhone: "",
  workPhone: "",
  street: "",
  city: "",
  stateOrProvince: "",
  country: "",
  receiveEmailAllowed: true
};

export const createParams = values => {
  const params = new URLSearchParams();

  values.username && params.append("username", values.username);
  values.firstName && params.append("firstName", values.firstName);
  values.lastName && params.append("lastName", values.lastName);
  values.middleName && params.append("middleName", values.middleName);
  values.nickName && params.append("nickName", values.nickName);
  values.birthday &&
    params.append("birthday", values.birthday.format("YYYY-MM-DD"));
  if (values.gender && values.gender.value) {
    params.append("gender", values.gender.value);
  }
  values.email && params.append("email", values.email);
  values.mobilePhone && params.append("mobilePhone", values.mobilePhone);
  values.homePhone && params.append("homePhone", values.homePhone);
  values.workPhone && params.append("workPhone", values.workPhone);
  values.street && params.append("street", values.street);
  values.city && params.append("city", values.city);
  values.stateOrProvince &&
    params.append("stateOrProvince", values.stateOrProvince);
  values.country && params.append("country", values.country);
  values.password && params.append("password", values.password);
  values.passwordConfirmation && params.append("passwordConfirmation", values.passwordConfirmation);
  values.receiveEmailAllowed &&
    params.append("receiveEmailAllowed", values.receiveEmailAllowed);

  return params;
};

export default connect(
  null,
  { sessionStatusLoadLogin }
)(FormikLoginForm);
