import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import AttributeForm, {
  createParams,
  initialValues,
  validationSchema,
  getPersonalSettings
} from "./AttributeForm";
import { extractSelectOption } from "../../../utils/app.util";
import { GENDER_OPTIONS } from "../../../constants/app.constant";
import { HOSTNAME } from "../../../constants/api.constant";
import Frame from "../../../components/Frame";
import PageHeader from "../../../components/PageHeader";
import moment from "moment";
import { FormattedMessage } from "react-intl";

const FormikEditForm = props => {
  const {
    ...rest
  } = props;

  const [loading, setLoading] = React.useState(false);

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating client`);
    }, PROGRESS_TIME_LIMIT);
    
    return axios
      .put(`${HOSTNAME}/api/personalSettings`, createParams(values))
      .then(() => {
        notify(`Updated`, "success");
        
        values.password = values.passwordConfirmation = "";

        formikActions.resetForm({values: values});
      })
      .catch(error => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        /*if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));
        }*/

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found for client`,
              variant: "warning"
            },
            403: {
              message: `Access denied to update clients`,
              variant: "error"
            }
          },
          {
            message: `Unable to update client`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={props => (
        <Frame>
          <PageHeader
            title={<FormattedMessage id="navigation.myInfo" />}
          />
          <form onSubmit={props.handleSubmit}>
            <AttributeForm
              {...props}
            />
          </form>
        </Frame>
      )}
    />
  );
};

export default FormikEditForm;