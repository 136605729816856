import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import localeService from "../../services/locale.service";

export default function(props) {
  const { open, onClose, onConfirm, title, message } = props;
  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography component="h2" variant="h4">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}><FormattedMessage id="dialog.button.cancel" /></Button>
        <Button
          onClick={() => {
            onClose();
            onConfirm && onConfirm();
          }}
          color="primary"
        >
          <FormattedMessage id="dialog.button.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
