import React, { useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import ErrorFilterResultPlaceholder from "./filters/ErrorFilterResultPlaceholder";
import EmptyFilterResultPlaceholder from "./filters/EmptyFilterResultPlaceholder";
import CancelConfirmationDialog from "./CancelConfirmationDialog";
import WaitListConfirmationDialog from "./WaitListConfirmationDialog";
import Frame from "./Frame";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  table: {
    marginTop: theme.spacing(3)
  },
  emptyFilterResult: {
    flex: "1 1 auto"
  },
  errorFilterResult: {
    flex: "1 1 auto"
  },
  progress: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const UpcomingAppointments = props => {
  const {
    filterForm: FilterForm,
    filterFormInitialValues,
    createFormDialog: CreateFormDialog,
    editFormDialog: EditFormDialog,
    displayDialog: DisplayDialog,
    removeConfirmationDialog: RemoveConfirmationDialog,
    listingTable: ListingTable,
    onFilter,
    api,
    request,
    parent,
    initialPageSize,
    status,
    method
  } = props;
  
  let _method = 'get';
  switch(method){
    case 'post':
    case 'put':
      _method = method;
  }

  const classes = useStyles();

  /*
  Status for loading listing table
   */

  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingTableError, setLoadingTableError] = useState(false);
  const [showTableWhileLoading, setShowTableWhileLoading] = useState(false);

  /*
  Filter
   */

  const [filters, setFilters] = React.useState(
    filterFormInitialValues
      ? onFilter
        ? onFilter(filterFormInitialValues)
        : filterFormInitialValues
      : {}
  );
  const handleFilterSubmit = values => {
    setShowTableWhileLoading(false);
    setPageNumber(0);

    setFilters(onFilter ? onFilter(values) : values);
  };
  
  const [
    cancelConfirmationDialogState,
    setCancelConfirmationDialogState
  ] = React.useState({
    open: false
  });

  const handleCancelConfirmationDialogClose = React.useCallback(() => {
    setCancelConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, [filters]);

  const handleCancel = entity => {
    setCancelConfirmationDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const cancelConfirmationDialog = (
    <CancelConfirmationDialog
      {...cancelConfirmationDialogState}
      onClose={handleCancelConfirmationDialogClose}
      onSuccess={() => {
        setFilters({
          ...filters,
        }); /* For refreshing */
      }}
      onBefore={targetEntity => {
      }}
      onAfter={targetEntity => {
      }}
    />
  );
  
  const [
    waitListConfirmationDialogState,
    setWaitListConfirmationDialogState
  ] = React.useState({
    open: false
  });

  const handleWaitListConfirmationDialogClose = React.useCallback(() => {
    setWaitListConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, [filters]);

  const handleWaitListConfirm = entity => {
    setWaitListConfirmationDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const waitListConfirmationDialog = (
    <WaitListConfirmationDialog
      {...waitListConfirmationDialogState}
      onClose={handleWaitListConfirmationDialogClose}
      onSuccess={() => {
        setFilters({
          ...filters,
        }); /* For refreshing */
      }}
      onBefore={targetEntity => {
      }}
      onAfter={targetEntity => {
      }}
    />
  );

  /*
   Listing table pagination
   */

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize || 10);
  const [totalCount, setTotalCount] = useState(0);

  const handlePageNumberChange = (event, page) => {
    setPageNumber(page);
    setShowTableWhileLoading(true);
  };

  const handlePageSizeChange = event => {
    setPageSize(event.target.value);
    setPageNumber(0);
    setShowTableWhileLoading(true);
  };

  /*
  Table entities
   */

  const [entities, setEntities] = useState([]);

  /*
  Listing table
   */
  const listingTable = (
    <ListingTable
      className={classes.table}
      entities={entities}
      onCancel={handleCancel}
      onWaitListConfirm={handleWaitListConfirm}
      totalCount={totalCount}
      pageNumber={pageNumber}
      pageSize={pageSize}
      handlePageNumberChange={handlePageNumberChange}
      handlePageSizeChange={handlePageSizeChange}
      loading={loadingTable}
      parent={parent}
    />
  );

  /*
  Load entities
   */

  useLayoutEffect(() => {
    let active = true;

    setLoadingTable(true);
    setLoadingTableError(false);

    const params = {
      ...filters,
      startDay: moment().startOf("day"),
      pageNumber: pageNumber,
      pageSize: pageSize,
      status: status
    };

    const result = request
      ? request(params)
      : axios[_method](api, createParams(params));

    result
      .then(response => {
        active && setEntities(response.data["content"] || []);
        active && setTotalCount(response.data["totalElements"]);
        active && setLoadingTableError(false);
      })
      .catch(() => {
        active && setLoadingTableError(true);
      })
      .finally(() => {
        active && setLoadingTable(false);
      });

    return () => {
      active = false;
    };
  }, [api, filters, pageNumber, pageSize, request]);

  return (
    <Frame className={classes.root}>
      {FilterForm && (
        <FilterForm
          initialValues={filterFormInitialValues}
          loading={loadingTable}
          onSubmit={handleFilterSubmit}
          parent={parent}
        />
      )}
      {loadingTableError ? (
        <ErrorFilterResultPlaceholder className={classes.errorFilterResult} />
      ) : loadingTable ? (
        showTableWhileLoading ? (
          listingTable
        ) : (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )
      ) : entities && entities.length > 0 ? (
        listingTable
      ) : (
        <EmptyFilterResultPlaceholder className={classes.emptyFilterResult} />
      )}
      {cancelConfirmationDialog}
      {waitListConfirmationDialog}
    </Frame>
  );
};

const createParams = values => {
  const { startDay, view, category, instructor, pageNumber, pageSize } = values;

  const params = new URLSearchParams();

  params.append("startDay", startDay.format("YYYY-MM-DD"));

  category && params.append(`categories[0]`, category.id);
  instructor && params.append(`instructors[0]`, instructor.id);

  params.append("page", pageNumber);
  params.append("size", pageSize);

  params.append("status", values.status);

  return params;
};

export default UpcomingAppointments;
