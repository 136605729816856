import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { GENDER_OPTIONS } from "../../../constants/app.constant";
import { Formik, Form, useFormikContext } from 'formik';
import FormikTextField from "../../../components/inputs/FormikTextField";
import Frame from "../../../components/Frame";
import PasswordField from "../../../components/inputs/PasswordField";
import FormikAutocomplete from "../../../components/inputs/FormikAutocomplete";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import axios from "axios";
import { HOSTNAME } from "../../../constants/api.constant";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(theme => ({
  sectionLabel: {
    ...theme.typography.h5
  }
}));

const AttributeForm = props => {
  const { onSubmit, onClose, ...rest } = props;

  const { values, resetForm, isSubmitting, isValid, dirty } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  const classes = useStyles();
  
  async function getPersonalSettings(){
    axios
      .get(`${HOSTNAME}/api/personalSettings`)
      .then(response => {
        const receivedValues = response.data;

        normalizeReceivedValues(receivedValues);
        
        initialValues = receivedValues;

        resetForm({ values: receivedValues });
      })
      .catch(error => {
        if(typeof onClose === "function") onClose();

        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to class`,
              variant: "error"
            }
          },
          {
            message: `Unable to fetch class`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  React.useEffect(() => {
    getPersonalSettings(); 
  }, [resetForm, notifyApiError, onClose]);

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <PasswordField
              fullWidth
              label={<FormattedMessage id="profile.form.passwordOptional" />}
              name="password"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PasswordField
              fullWidth
              label={<FormattedMessage id="profile.form.passwordConfirm" />}
              name="passwordConfirmation"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.firstName" />}
              name="firstName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.lastName" />}
              name="lastName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormikTextField
              fullWidth
              label={<FormattedMessage id="profile.form.middleName" />}
              name="middleName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormikTextField
              fullWidth
              label={<FormattedMessage id="profile.form.nickName" />}
              name="nickName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.mobilePhone" />}
              name="mobilePhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label={<FormattedMessage id="profile.form.homePhone" />}
              name="homePhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label={<FormattedMessage id="profile.form.workPhone" />}
              name="workPhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.street" />}
              name="street"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.city" />}
              name="city"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.stateProvince" />}
              name="stateOrProvince"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              required
              fullWidth
              label={<FormattedMessage id="profile.form.country" />}
              name="country"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikSwitch
              name="receiveEmailAllowed"
              label={<FormattedMessage id="profile.form.emailNotification" />}
              description={<FormattedMessage id="profile.form.emailNotificationEnable" />}
              FormLabelProps={{
                className: classes.sectionLabel
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <Button
        variant="outlined"
        type="submit"
        color="default"
        color="primary"
        disabled={isSubmitting || !dirty}
      >
        {!isSubmitting ? (
          <FormattedMessage id="profile.form.button.submit" />
        ) : (
          <FormattedMessage id="profile.form.button.loading" />
        )}
      </Button>
    </div>
  );
}

export default AttributeForm;

export const validationSchema = Yup.object().shape({
  /*username: Yup.string()
    .min(3, "At least 3 characters long")
    .max(100, "No more than 150 characters long")
    .matches(
      /^[a-zA-Z0-9_.]*$/,
      "Only alphanumeric characters, dots and underscores allowed"
    )
    .required("Required"),*/
  password: Yup.string()
    .min(8, "At least 8 characters long")
    .max(20, "No more than 20 characters long"),
  passwordConfirmation: Yup.string().when('password', (value, schema) => {
        if (!value) {
            return schema.notRequired()
        }
        return schema.oneOf([Yup.ref('password')], "Passwords do not match").required("Passwords do not match")
    }),
  firstName: Yup.string()
    .max(50, "No more than 50 characters long")
    .required("Required"),
  lastName: Yup.string().max(50, "No more than 50 characters long")
    .required("Required"),
  middleName: Yup.string().max(50, "No more than 50 characters long"),
  nickName: Yup.string().max(50, "No more than 50 characters long"),
  email: Yup.string().email("Invalid email")
    .required("Required"),
  mobilePhone: Yup.string()
    .min(8, "Invalid Mobile Number")
    .max(8, "Invalid Mobile Number")
    .required("Required"),
  street: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  stateOrProvince: Yup.string().required("Required"),
  country: Yup.string().required("Required")
});

export let initialValues = {
  id: null,
  password: "",
  passwordConfirmation: "",
  firstName: "",
  lastName: "",
  middleName: "",
  nickName: "",
  email: "",
  mobilePhone: "",
  homePhone: "",
  workPhone: "",
  street: "",
  city: "",
  stateOrProvince: "",
  country: "",
  receiveEmailAllowed: false
};

export const createParams = values => {
  const params = new URLSearchParams();

  //values.username && params.append("username", values.username);
  values.firstName && params.append("firstName", values.firstName);
  values.lastName && params.append("lastName", values.lastName);
  values.middleName && params.append("middleName", values.middleName);
  values.nickName && params.append("nickName", values.nickName);
  values.email && params.append("email", values.email);
  values.mobilePhone && params.append("mobilePhone", values.mobilePhone);
  values.homePhone && params.append("homePhone", values.homePhone);
  values.workPhone && params.append("workPhone", values.workPhone);
  values.street && params.append("street", values.street);
  values.city && params.append("city", values.city);
  values.stateOrProvince &&
    params.append("stateOrProvince", values.stateOrProvince);
  values.country && params.append("country", values.country);
  values.password && params.append("password", values.password);
  values.passwordConfirmation && params.append("password", values.passwordConfirmation);
  values.receiveEmailAllowed &&
    params.append("receiveEmailAllowed", values.receiveEmailAllowed);

  return params;
};


const normalizeReceivedValues = receivedValues => {
  //receivedValues.username || (receivedValues.username = "");
  receivedValues.password || (receivedValues.password = "");
  receivedValues.passwordConfirmation || (receivedValues.passwordConfirmation = "");
  receivedValues.firstName || (receivedValues.firstName = "");
  receivedValues.lastName || (receivedValues.lastName = "");
  receivedValues.middleName || (receivedValues.middleName = "");
  receivedValues.nickName || (receivedValues.nickName = "");
  //receivedValues.email || (receivedValues.email = "");
  receivedValues.mobilePhone || (receivedValues.mobilePhone = "");
  receivedValues.homePhone || (receivedValues.homePhone = "");
  receivedValues.workPhone || (receivedValues.workPhone = "");
  receivedValues.street || (receivedValues.street = "");
  receivedValues.city || (receivedValues.city = "");
  receivedValues.stateOrProvince || (receivedValues.stateOrProvince = "");
  receivedValues.country || (receivedValues.country = "");
  receivedValues.receiveEmailAllowed ||
    (receivedValues.receiveEmailAllowed = false);
};