import React, { useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import ErrorFilterResultPlaceholder from "./filters/ErrorFilterResultPlaceholder";
import EmptyFilterResultPlaceholder from "./filters/EmptyFilterResultPlaceholder";
import Frame from "./Frame";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  table: {
    marginTop: theme.spacing(3)
  },
  emptyFilterResult: {
    flex: "1 1 auto"
  },
  errorFilterResult: {
    flex: "1 1 auto"
  },
  progress: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const UpcomingAppointments = props => {
  const {
    filterForm: FilterForm,
    filterFormInitialValues,
    createFormDialog: CreateFormDialog,
    editFormDialog: EditFormDialog,
    displayDialog: DisplayDialog,
    removeConfirmationDialog: RemoveConfirmationDialog,
    listingTable: ListingTable,
    onFilter,
    api,
    request,
    parent,
    initialPageSize,
    status,
    method
  } = props;
  
  let _method = 'get';
  switch(method){
    case 'post':
    case 'put':
      _method = method;
  }

  const classes = useStyles();

  /*
  Status for loading listing table
   */

  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingTableError, setLoadingTableError] = useState(false);
  const [showTableWhileLoading, setShowTableWhileLoading] = useState(false);

  /*
  Filter
   */

  const [filters, setFilters] = React.useState(
    filterFormInitialValues
      ? onFilter
        ? onFilter(filterFormInitialValues)
        : filterFormInitialValues
      : {}
  );
  const handleFilterSubmit = values => {
    setShowTableWhileLoading(false);
    setPageNumber(0);

    setFilters(onFilter ? onFilter(values) : values);
  };

  /*
  Form dialog for create
   */

  const [createFormDialogOpen, setCreateFormDialogOpen] = useState(false);

  const handleCreateFormDialogOpen = React.useCallback(() => {
    setCreateFormDialogOpen(true);
  }, []);

  const handleCreateFormDialogClose = React.useCallback(() => {
    setCreateFormDialogOpen(false);
  }, []);

  const createFormDialog = CreateFormDialog && (
    <CreateFormDialog
      open={createFormDialogOpen}
      onOpen={handleCreateFormDialogOpen}
      onClose={handleCreateFormDialogClose}
      onSuccess={() => {
        setShowTableWhileLoading(true);
        setFilters({ ...filters });
      }}
      parent={parent}
    />
  );

  /*
  Form dialog for edit
   */

  const [editFormDialogState, setEditFormDialogState] = useState({
    open: false
  });

  const handleEditFormDialogOpen = React.useCallback(() => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      open: true
    }));
  }, []);

  const handleEditFormDialogClose = React.useCallback(() => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const editFormDialog = EditFormDialog && (
    <EditFormDialog
      {...editFormDialogState}
      onOpen={handleEditFormDialogOpen}
      onClose={handleEditFormDialogClose}
      onSuccess={() => {
        setEditFormDialogState(prevState => ({
          ...prevState,
          entity: null
        }));

        setShowTableWhileLoading(true);
        setFilters({ ...filters });
      }}
      onBefore={targetEntity => {
        setEntities(
          entities.map(entity => {
            if (entity.id === targetEntity.id) {
              return {
                ...entity,
                updating: true
              };
            }

            return entity;
          })
        );
      }}
      onAfter={targetEntity => {
        setEntities(
          entities.map(entity => {
            if (entity.id === targetEntity.id) {
              return {
                ...entity,
                updating: undefined
              };
            }

            return entity;
          })
        );
      }}
      parent={parent}
    />
  );

  /*
  Dialog for display information
   */

  const [displayDialogState, setDisplayDialogState] = React.useState({
    open: false
  });

  const handleDisplayDialogClose = React.useCallback(() => {
    setDisplayDialogState(prevState => ({
      ...prevState,
      open: false,
      entity: null
    }));
  }, []);

  const displayDialog = DisplayDialog && (
    <DisplayDialog {...displayDialogState} onClose={handleDisplayDialogClose} />
  );

  /*
  Dialog for remove confirmation
   */

  const [
    removeConfirmationDialogState,
    setRemoveConfirmationDialogState
  ] = React.useState({
    open: false
  });

  const handleRemoveConfirmationDialogClose = React.useCallback(() => {
    setRemoveConfirmationDialogState(prevState => ({
      ...prevState,
      open: false
    }));
  }, []);

  const removeConfirmationDialog = RemoveConfirmationDialog && (
    <RemoveConfirmationDialog
      {...removeConfirmationDialogState}
      onClose={handleRemoveConfirmationDialogClose}
      onSuccess={() => {
        setShowTableWhileLoading(true);

        if (entities.length === 1 && pageNumber > 0) {
          setPageNumber(pageNumber - 1);
        } else {
          setFilters({ ...filters });
        }
      }}
      onBefore={targetEntity => {
        setEntities(
          entities.map(entity => {
            if (entity.id === targetEntity.id) {
              return {
                ...entity,
                deleting: true
              };
            }

            return entity;
          })
        );
      }}
      onAfter={targetEntity => {
        setEntities(
          entities.map(entity => {
            if (entity.id === targetEntity.id) {
              return {
                ...entity,
                deleting: undefined
              };
            }

            return entity;
          })
        );
      }}
      parent={parent}
    />
  );

  /*
  Handle CRUD actions
   */

  const handleView = entity => {
    setDisplayDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const handleCreate = () => {
    setCreateFormDialogOpen(true);
  };

  const handleEdit = entity => {
    setEditFormDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  const handleRemove = entity => {
    setRemoveConfirmationDialogState(prevState => ({
      ...prevState,
      entity: entity,
      open: true
    }));
  };

  /*
   Listing table pagination
   */

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize || 10);
  const [totalCount, setTotalCount] = useState(0);

  const handlePageNumberChange = (event, page) => {
    setPageNumber(page);
    setShowTableWhileLoading(true);
  };

  const handlePageSizeChange = event => {
    setPageSize(event.target.value);
    setPageNumber(0);
    setShowTableWhileLoading(true);
  };

  /*
  Table entities
   */

  const [entities, setEntities] = useState([]);

  /*
  Listing table
   */
  const listingTable = (
    <ListingTable
      className={classes.table}
      entities={entities}
      onView={handleView}
      onEdit={handleEdit}
      onDelete={handleRemove}
      totalCount={totalCount}
      pageNumber={pageNumber}
      pageSize={pageSize}
      handlePageNumberChange={handlePageNumberChange}
      handlePageSizeChange={handlePageSizeChange}
      loading={loadingTable}
      parent={parent}
    />
  );

  /*
  Load entities
   */

  useLayoutEffect(() => {
    let active = true;

    setLoadingTable(true);
    setLoadingTableError(false);

    const params = {
      ...filters,
      startDay: moment().startOf("day"),
      pageNumber: pageNumber,
      pageSize: pageSize,
      status: status
    };

    const result = request
      ? request(params)
      : axios[_method](api, createParams(params));

    result
      .then(response => {
        active && setEntities(response.data["content"] || []);
        active && setTotalCount(response.data["totalElements"]);
        active && setLoadingTableError(false);
      })
      .catch(() => {
        active && setLoadingTableError(true);
      })
      .finally(() => {
        active && setLoadingTable(false);
      });

    return () => {
      active = false;
    };
  }, [api, filters, pageNumber, pageSize, request]);

  return (
    <Frame className={classes.root}>
      {FilterForm && (
        <FilterForm
          initialValues={filterFormInitialValues}
          loading={loadingTable}
          onSubmit={handleFilterSubmit}
          parent={parent}
        />
      )}
      {loadingTableError ? (
        <ErrorFilterResultPlaceholder className={classes.errorFilterResult} />
      ) : loadingTable ? (
        showTableWhileLoading ? (
          listingTable
        ) : (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )
      ) : entities && entities.length > 0 ? (
        listingTable
      ) : (
        <EmptyFilterResultPlaceholder className={classes.emptyFilterResult} />
      )}
      {createFormDialog}
      {editFormDialog}
      {displayDialog}
      {removeConfirmationDialog}
    </Frame>
  );
};

const createParams = values => {
  const { startDay, view, category, instructor, pageNumber, pageSize } = values;

  const params = new URLSearchParams();

  params.append("startDay", startDay.format("YYYY-MM-DD"));

  let daySize = null;

  if (view === "DAILY") {
    daySize = 1;
  } else if (view === "WEEKLY") {
    daySize =
      moment(startDay)
        .endOf("isoWeek")
        .diff(startDay, "day") + 1;
  } else if (view === "MONTHLY") {
    daySize =
      moment(startDay)
        .endOf("month")
        .diff(startDay, "day") + 1;
  }

  daySize && params.append("daySize", daySize.toString());

  category && params.append(`categories[0]`, category.id);
  instructor && params.append(`instructors[0]`, instructor.id);

  params.append("page", pageNumber);
  params.append("size", pageSize);

  params.append("status", values.status);

  return params;
};

export default UpcomingAppointments;
