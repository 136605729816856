import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  paper: {
    width: 600
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(6, 3)
  }
}));

export default function LoadableDialog(props) {
  const {
    open,
    loading,
    onClose,
    title,
    children,
    paperClassName,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      {...rest}
      open={open}
      onClose={onClose}
      PaperProps={{
        className: clsx(classes.paper, paperClassName)
      }}
      disableRestoreFocus
      scroll="body"
      keepMounted
      maxWidth={false}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4">{title}</Typography>
        {onClose && (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
