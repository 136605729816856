import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import NavTree from "./NavTree";
import List from "@material-ui/core/List";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  }
}));

const NavSection = props => {
  const {
    className,
    component: Component,
    section,
    location,
    LeafNodeProps,
    ...rest
  } = props;

  const { title, items, forbidden } = section;

  const classes = useStyles();

  if (forbidden) {
    return null;
  }

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      {title && <Typography variant="overline">{title}</Typography>}
      <List>
        {items.map(item => {
          if (item.accessDenied) {
            return null;
          }

          return (
            <NavTree
              key={item.title}
              node={item}
              depth={0}
              LeafNodeProps={LeafNodeProps}
            />
          );
        })}
      </List>
    </Component>
  );
};

NavSection.defaultProps = {
  component: "nav"
};

export default NavSection;
