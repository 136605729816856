import React, { useState } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Divider, Drawer, useMediaQuery } from "@material-ui/core";
import navigationConfig from "../../../config/navigation.config";
import Navigation from "../../../components/Navigation";
import LocaleLink from "./LocaleLink";
import localeService from "../../../services/locale.service";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflowY: "auto"
  },
  content: {
    padding: theme.spacing(2)
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  logoImage: {
    width: 136
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  }
}));

const NavBar = props => {
  const { className, navOpen, toggleNavOpen, ...rest } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
  const drawerVariant = isMdDown ? "temporary" : "persistent";
  
  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);
  
  function handleLocaleChange(locale){
    setLocale(locale);
    window.sessionStorage.setItem("locale", locale);
    window.location.reload();
  }

  const navBarContent = (
    <div className={classes.content}>
      <div className={classes.logo}>
        <img
          className={classes.logoImage}
          src="/static/images/logos/logo.png"
          alt="logo"
        />
      </div>
      <Divider className={classes.divider} />
      <Navigation
        config={navigationConfig}
        className={classes.navigation}
        component="div"
        LeafNodeProps={{
          onClick: drawerVariant === "temporary" ? toggleNavOpen : undefined
        }}
      />
      <div style={{position: "absolute", bottom: "10px", left: "10px", right: "10px", margin: "auto"}}>
        <Divider className={classes.divider} />
        <Grid container direction="column">
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            spacing={1}
            className={classes.locale}
          >
            <Grid item>
              <LocaleLink to="en" current={locale} handleChange={handleLocaleChange}>
                English
              </LocaleLink>
            </Grid>
            <Grid item>
              <LocaleLink
                to="zh-HK"
                current={locale}
                handleChange={handleLocaleChange}
              >
                繁體中文
              </LocaleLink>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Drawer
        className={className}
        anchor="left"
        open={navOpen}
        onClose={toggleNavOpen}
        variant={drawerVariant}
      >
        <div {...rest} className={clsx(classes.root, className)}>
          {navBarContent}
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default NavBar;
