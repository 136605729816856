import React from "react";
import { Button } from "@material-ui/core";
import PageHeader from "../../../components/PageHeader";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import { Link } from "react-router-dom";
import {
  BUY_PACKAGE_PATH
} from "../../../constants/route.constant";

const MyPackagesPageHeader = props => {
  return (
    <PageHeader
      title="My Packages"
      right={
        <Link to={BUY_PACKAGE_PATH}>
          <Button color="secondary" variant="contained">
            Buy Package Plan
          </Button>
        </Link>
      }
    />
  );
};

export default MyPackagesPageHeader;
