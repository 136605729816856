import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from "./components/LoginForm";
import Copyright from "./components/Copyright";
import localeService from "../../services/locale.service";
import { IntlProvider } from "react-intl";
import LocaleLink from "./components/LocaleLink";
import { MESSAGES } from "../../constants/locale.constant";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.paper
  },
  image: {
    backgroundImage: "url(/static/images/login/bg.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  panel: {
    margin: theme.spacing(8, 7),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  login: {
    maxWidth: theme.spacing(60)
  },
  logo: {
    width: 200,
    marginBottom: theme.spacing(6)
  },
  locale: {
    margin: theme.spacing(6, 0, 1)
  }
}));

export default function LoginPage() {
  const classes = useStyles();

  const [locale, setLocale] = useState(localeService.detectLocaleIfPossible);
  
  function handleLocaleChange(locale){
    setLocale(locale);
    window.sessionStorage.setItem("locale", locale);
  }

  return (
    <IntlProvider locale={locale} messages={MESSAGES[locale]}>
      <Grid
        container
        component="main"
        className={classes.root}
        justify="space-evenly"
      >
        <Hidden smDown>
          <Grid xs sm md item className={classes.image} />
        </Hidden>
        <Grid item xs={12} sm={12} md={6} className={classes.login}>
          <div className={classes.panel}>
            <img
              className={classes.logo}
              src="/static/images/logos/logo.png"
              alt="logo"
            />
            <LoginForm />
            <Grid container direction="column">
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                spacing={1}
                className={classes.locale}
              >
                <Grid item>
                  <LocaleLink to="en" current={locale} handleChange={handleLocaleChange}>
                    English
                  </LocaleLink>
                </Grid>
                <Grid item>
                  <LocaleLink
                    to="zh-HK"
                    current={locale}
                    handleChange={handleLocaleChange}
                  >
                    繁體中文
                  </LocaleLink>
                </Grid>
              </Grid>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </IntlProvider>
  );
}
