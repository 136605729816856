import React, { useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useFormikContext } from "formik";
import AutoCompleteOffTextField from "./AutoCompleteOffTextField";
import { FormattedMessage } from "react-intl";
import localeService from "../../services/locale.service";

export default function(props) {
  const { name, ...rest } = props;
  
  const locale = window.sessionStorage.getItem("locale");

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    isSubmitting
  } = useFormikContext();

  return (
    <KeyboardDatePicker
      autoOk
      inputVariant="outlined"
      format="DD/MM/YYYY"
      placeholder="DD/MM/YYYY"
      margin="dense"
      fullWidth
      InputAdornmentProps={{ position: "start" }}
      KeyboardButtonProps={{ size: "small" }}
      TextFieldComponent={AutoCompleteOffTextField}
      disabled={isSubmitting}
      value={values[name]}
      onChange={date => {
        setFieldValue(name, date);
      }}
      {...rest}
      name={name}
      onClose={() => {
        setFieldTouched(name, true, false);
      }}
      onBlur={handleBlur}
      invalidDateMessage={locale == "en-US" ? "Invalid date format" : "無效的日期"}
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      onError={error => {
        if (error && errors[name] !== error) {
          setFieldError(name, error);
        }
      }}
    />
  );
}
