import React from "react";
import { Redirect, Route } from "react-router";
import securityService from "../services/security.service";
import { connect } from "react-redux";
import {
  SESSION_STATUS_COMPLETED_RESTORE,
  SESSION_STATUS_COMPLETED_LOGIN
} from "./SecurityFilter/actions/sessionStatus.action";
import { LOGIN_PATH } from "../constants/route.constant";

class SecuredRoute extends React.Component {
  render() {
    const { component: Component, status, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          securityService.hasAccessToken() &&
          (status === SESSION_STATUS_COMPLETED_RESTORE ||
            status === SESSION_STATUS_COMPLETED_LOGIN) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: LOGIN_PATH,
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = ({ session }) => {
  return { status: session.status };
};

export default connect(mapStateToProps)(SecuredRoute);
